import React , {useRef} from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';
import Logo from '../../../assets/images/logo.svg';

interface props{
	style: {
		[index: string]: string | number
	}
}

export const Footer = (
	props: props
) => {
	const windowSize = useRef([window.innerWidth , window.innerHeight]);
	console.log(windowSize.current[0] , windowSize.current[1]);
  return (
    <footer style ={{...props.style , marginTop: 0 }}>
			<div className ="footer line-1">
				<div className ="content">
					<div className ="row">

						<div className ="col-3 col-12-sm">
							<ul className ="links">
								<li className='footer-text' ><a href="/aboutRarissime">About</a></li>
								<li className='footer-text'><a href="https://www.clapart.com" target="_blank">CLAPART</a></li>
								<li className='footer-text'><a href="https://www.clapart.com/contact">Contact</a></li>
								{/* <li><a href="#">Partners</a></li> */}
								<li className='footer-text'><a href="#faq">FAQ</a></li>
							</ul>
						</div>

						<div className ="col-3 col-6-sm">
							<ul className ="links">
								{/* <li><a href="#partners">Partners</a></li> */}
								{/* <li><a href="#">Integration</a></li> */}
								{/* <li><a href="#">Sitemap</a></li> */}
							</ul>
						</div>

						<div className ="col-6 col-12-sm right info">
							<img src={Logo} alt="Rarissime by Clapart" />
							<div className ="social">
								<a href="https://twitter.com/HoodHeroesClub" target="_blank"><span className ="fa fab fa-twitter"></span></a>
								{/* <a href="#" target="_blank"><span className ="fa fab fa-telegram"></span></a> */}
								<a href="https://discord.gg/ZBN4xD6MyQ" target="_blank"><span className ="fa fab fa-discord"></span></a>
								{/* <a href="#" target="_blank"><span className ="fa fab fa-linkedin"></span></a> */}
								{/* <a href="#" target="_blank"><span className ="fa fab fa-instagram"></span></a> */}
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className ="footer line-2">
				<div className ="content">
					<div className ="row">

						<div className ="col-8">
							<p className ="copyright">Copyright &copy; 2023 Rarissime by Clapart. All rights reserved</p>
						</div>
						<div className ="col-4">
							<p className ="info">
								<a href="/terms">Terms</a>
								<a href="/privacy">Privacy</a>
								<a href="/cookies">Cookies</a>
								<a href="/raffleRules">Raffle Rules</a>
							</p>
						</div>

					</div>
				</div>
			</div>

		</footer>
    // <footer className Name='text-center mt-2 mb-3'>
    //   <div>
    //     <a
    //       {...{
    //         target: '_blank'
    //       }}
    //       className Name='d-flex align-items-center'
    //       href='https://multiversx.com/'
    //     >
    //       Made with <HeartIcon className Name='mx-1' /> by MultiversX Network.
    //     </a>
    //   </div>
    // </footer>
  );
};
