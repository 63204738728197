import React from 'react';
import {staticsNumAndDes} from 'helpers/constant';

const StaticNumbers = () => {
    return (
        <div className="container categories-list metrics-list">

            <div className="row">

                {
                    staticsNumAndDes.map((item , index) => (
                        <div className="col-6 col-12-sm" key = {index}>
                            <div className="category metrics  statistics">
                                <h3>{`${item.num}${item.unit}`}</h3>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    );
};

export { StaticNumbers };
