import React , {useRef} from 'react';
import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers';
import { useLocation } from 'react-router-dom';
import { routes, routeNames } from 'routes';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const Layout = ({ children }: { children: React.ReactNode }) => {

  const location = useLocation();
  const {search} = location;
  const listRef = useRef();


  return (
    <>
      <Navbar
        style = {
          {
            // display: location.pathname == '/payment'?  'none' : ''
          }
        }
      />
      {/* <main className='d-flex flex-column flex-grow-1'> */}
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      {/* </main> */}
      <Footer
        style = {{
          // display: location.pathname == '/payment'?  'none' : '',
          // position: location.pathname == '/unlock'? 'absolute': '',
          // bottom: location.pathname == '/unlock'?0: '',
          // width:location.pathname == '/unlock'? '100%': ''
        }}
      />
    </>
  );
};
