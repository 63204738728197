import React from 'react';
import {clubRarissime} from 'helpers/constant';

const ClubRarissime = () => {
    return (
        <div className="container collection-intro" id = "rarissime_club">
            <div className="row list-title">
                <div className="landing-title center">
                    <h2>CLUB RARISSIME</h2>
                </div>
                <div className="title-separator"></div>
                <div className="center width-80-text">
                    {
                        clubRarissime.content.map((item , index) => (
                            <p key = {index}>
                                {item}
                            </p>
                        ))
                    }
                    {/* <p>
                    There will be a title (RARISSIME CLUB ),
                    a short text (we will provide) and a JOIN CLUB button that will lead to another page
                    (the graphics for this page is not yet ready) where we will have a form they can apply.
                    For applying they will receive a coupon they can use in future purchases. Here we have to talk more about this zone integration.
                    </p> */}
                </div>
            </div>
        </div>
    );
};

export { ClubRarissime };
