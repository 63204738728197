import React, { useEffect, useState } from 'react';
import LandingImage from '../../assets/images/hero.jpg';

const Landing = () => {

	const [firstDate , setFirstDate] = useState(new Date().getTime());
	const [secondDate , setSecondDate] = useState(new Date(Date.parse('2023-04-07T20:00:00+03:00')).getTime());
	const [thirdDate , setThirdDate] = useState(new Date(Date.parse('2023-04-07T19:00:00+03:00')).getTime());

	useEffect(() => {
		const interval = setInterval(() => {
			setFirstDate(firstDate => firstDate + 1000);
		} , 1000);
		return () => clearInterval(interval);
	} , []);

    return (
        <div className="hero landing-hero">
			<img src={LandingImage} className="landing-banner" alt="" />

			<div className="landing-captions">
				<div className="content">
					<div className="row">
						<div className="col-12 col-12-sm">

							<small>Launched on April 7, 2023</small>
							<h1>Connect-R's Hood Heroes<br />NFT Collection</h1>
							
							{
								secondDate > firstDate?
									<div id="counter" className="clock">
										<div className="line">
											<h5 id="days">{Math.floor( (secondDate - firstDate ) / 86400  / 1000 ) }</h5>
											<p>Days</p>
										</div>
										<div className="line">
											<h5 id="hours">{Math.floor( (secondDate - firstDate ) / 3600  / 1000 ) % 24 }</h5>
											<p>Hours</p>
										</div>
										<div className="line">
											<h5 id="mins">{Math.floor( (secondDate - firstDate ) / 60  / 1000 ) % 60 }</h5>
											<p>Minutes</p>
										</div>
										<div className="line">
											<h5 id="secs">{Math.floor( (secondDate - firstDate ) / 1000 ) % 60 }</h5>
											<p>Seconds</p>
										</div>
									</div>
									:''
							}

							

							<a href="https://discord.gg/ZBN4xD6MyQ" target="_blank" className="button button-very-big invert-button">Join Discord Channel</a>
							<a href="https://xoxno.com/collection/THH-224018" target="_blank"  className="button button-very-big ml5rem" style = {{
								pointerEvents: (firstDate >= new Date(Date.parse('2023-03-01T00:00:00')).getTime()) && (firstDate < secondDate)? 'none': 'auto',
								background: (firstDate >= new Date(Date.parse('2023-03-01T00:00:00')).getTime()) && (firstDate < secondDate)? 'gray': 'auto',
								border: (firstDate >= new Date(Date.parse('2023-03-01T00:00:00')).getTime()) && (firstDate < secondDate)? 'gray': 'auto'
							 }} >Find us on XOXNO</a>
							
							{
								thirdDate > firstDate?
									<>
										<div className="row">
											<div className="col-12 center  white-text">
												<a href=" https://form.typeform.com/to/stChVeIV" className="button button-huge invert-button call-to-action view-all wunset" style = {{ paddingLeft: '35px' }}>Get on the Whitelist and get your Hood Hero NFT!</a>
											</div>
										</div>
										<div className='row'>
											<div className="center width-80-text white-text">
												Get on the Whitelist and receive the minting link 15 minutes before 20:00 on Launch Day (April 7th)
											</div>
										</div>
									</>
									:''
							}

							
						</div>
					</div>
				</div>
			</div>
		</div>
    );
};

export {Landing};
