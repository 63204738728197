import React , {Dispatch, useState} from 'react';
import {rarity ,staticsNumAndDes , roadmaps, FAQ } from 'helpers/constant';

interface RarityProps{
    setRarityStatus: Dispatch<string>
}

const Rarity = ({

    setRarityStatus

}: RarityProps) => {

    // const navigate = useNavigate();

    return (
        <div id="features" className="container categories-list">

            <div className="row list-title">
                <div className="landing-title center">
                    <h2>RARITY</h2>
                </div>
            </div>

            <div className="row tiers">

                <div className="roadmap-bg"></div>

                {
                    rarity.map((item , index) => (
                        <a
                            key = {index}
                            className="col-3 col-12-md"
                            onClick = { () => {
                                    setRarityStatus(item.title);
                                }
                            }
                            style = {{ color: 'white' }}
                            href = '#holder_benefit'
                        >
                            <div className="nft tier rarity" >
                                <h3 className = { item.color }>{item.title}</h3>
                                <small>{`${item.nfts} NFTs`}</small>
                                {
                                    item.describe.map( term => ( <p>- {term} </p> ))
                                }
                            </div>
                        </a>
                    ))
                }

            </div>

        </div>
    );
};

export { Rarity };
