import React from 'react';
import { Landing } from './Landing';
import { Main } from './Main';


const Collection = () => {
    return (
        <>
            <Landing />
            <Main />
        </>
    );
};

export { Collection };
