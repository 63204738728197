import React from 'react';
import { roadmaps} from 'helpers/constant';

const Roadmap = () => {
    return (
        <div id="roadmap" className="container roadmap-list">

            <div className="row list-title">
                <div className="landing-title center">
                    <h2>Roadmap</h2>
                </div>
            </div>

            <div className="row roadmap">

                <div className="roadmap-bg"></div>

                {
                    roadmaps.map((item , index) => (
                        <div className={`col-12 col-12-sm roadmap-box ${item.finished?'finished':''} `} key = {index}>
                            <div className="roadmap-title">
                                <small>{item.phaseI}</small>
                                <h3>{item.date}</h3>
                            </div>
                            <div className="roadmap-content">
                                <ul className = {index % 2 == 0 ? 'even' : 'odd'}>
                                    {
                                        item.events.map((_item , _index) => (
                                            <li key = {_index}>
                                                {_item}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    );
};

export { Roadmap };
