import { cookies } from 'helpers/constant';
import React from 'react';
import { StaticInfo } from './StaticInfo';


const Cookies = () => {
    return (
        <StaticInfo info = { cookies }  />
    );
};


export {Cookies};
