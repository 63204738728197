import React , { useEffect, useState } from 'react';
// import { loadStripe , StripeElementsOptions } from '@stripe/stripe-js';
// import { Elements ,  } from '@stripe/react-stripe-js';
// import { PUBLIC_STRIPE_KEY } from 'config';
import { CheckoutForm } from './CheckoutForm';
import {ccproWidgetUrl, iframeCcproWidgetUrl} from 'config';

// const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

const Fiat = () => {

    // const [ clientSecret , setClientSecret ] = useState('');

    // useEffect(() => {
    //     fetch('http://localhost:3000/payment/create' , {
    //         method: 'POST',
    //         mode: 'cors',
    //         headers: {'Content-Type': 'application/json' , 'Access-Control-Allow-Origin': '*'},
    //         body: JSON.stringify({items: [{id: 'xl-tshirt'}]})
    //     })
    //     .then(res => res.json())
    //     .then(data => setClientSecret(data.clientSecret));
    // } , []);

    // const appearance : {
    //     theme: 'stripe' | 'night' | 'flat' | 'none' | undefined,
    //     variables: any
    // } = {
    //     theme: 'stripe',
    //     variables: {
    //         colorText: 'secondary',
    //     }
    // };
    // const options : StripeElementsOptions  = {
    //     clientSecret,
    //     appearance,
    // };

    return (
        <div className="App">
            <h4 style = {{
                fontSize: '1.5em',
                textAlign: 'center',
             }}>

            Fiat Payment
            </h4>
        {/* {clientSecret && (
            <Elements options = {options} stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        )} */}
        <iframe
            src={iframeCcproWidgetUrl}
            allowTransparency = {true}
            loading='eager'
            sandbox="allow-popups allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups-to-escape-sandbox"
            height="1330px"
            width="100%"
        ></iframe>
        </div>
    );
};

export {Fiat};
