import React from 'react';
import {text} from 'helpers/constant';

const Mission = () => {
    return (
        <div className="container collection-intro">
            <div className="row list-title">
                <div className="landing-title center">
                    <h2>Mission</h2>
                </div>
                <div className="title-separator"></div>
                <div className="center width-80-text">
                    {
                        text.mission.map((item , index) => (
                            <p key = {index}>
                                {item}
                            </p>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export { Mission };
