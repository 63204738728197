import { rarrissimeRaffles } from 'helpers/constant';
import React from 'react';
import { StaticInfo } from './StaticInfo';


const RarissimeRaffles = () => {
    return (
        <StaticInfo info = { rarrissimeRaffles }  />
    );
};


export {RarissimeRaffles};
