import React from 'react';
import {

    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton

} from '@multiversx/sdk-dapp/UI';
import { AuthRedirectWrapper } from 'components';
import {walletConnectV2ProjectId} from 'config';
import {routeNames} from 'routes';


const Wallets = () => {
    const commonProps = {
        callbackRoute: routeNames.mint,
        nativeAuth: true, // optional,
    };
    return (
        <AuthRedirectWrapper>
            <div className = "row">
				<div className = "col-12 custom-wallet-css">
                    <ExtensionLoginButton
                    loginButtonText='Maiar Defi Wallet'
                    {...commonProps}
                    className = 'cus-wallet'
                    />
                </div>
				<div className = "col-12 custom-wallet-css">
                    <WebWalletLoginButton
                    loginButtonText='Web wallet'
                    {...commonProps}
                    className = 'cus-wallet'
                    />
                </div>
            </div>
            <div className = 'row '>
				<div className = "col-12 custom-wallet-css">
                    <LedgerLoginButton
                    loginButtonText='Ledger'
                    className='test-class_name cus-wallet'
                    {...commonProps}
                    />
                </div>
				<div className = "col-12 custom-wallet-css">
                    <WalletConnectLoginButton
                    loginButtonText='xPortal'
                    {...commonProps}
                    {...(walletConnectV2ProjectId
                        ? {
                            isWalletConnectV2: true
                        }
                        : {})}
                    className = 'cus-wallet'
                    />
                </div>
            </div>
        </AuthRedirectWrapper>
    );
};


export {Wallets};
