import React, { Dispatch } from 'react';

import multiversx from '../../../assets/images/logos/multiversx.png';


import Metamask from '../../../assets/images/metamask-logo.png';
import CoinBase from '../../../assets/images/coinbase-logo.png';

import {
	useGetAccount
} from '@multiversx/sdk-dapp/hooks';
import { useNavigate } from 'react-router-dom';

interface props {
	setStatus: Dispatch<string>
}

const Category = (
	props: props
) => {

	const navigate = useNavigate();


	const {
		address
	} = useGetAccount();
	console.log(address);
	return (
		<div className="row wallets">
			<div className="col-6">
				<div className="wallet"
					style={{ cursor: 'pointer' }}
					onClick={(event) => {
						if (address) navigate('/mint');
						else {
							event.preventDefault();
							props.setStatus('multiversx');

						}
					}}
				>
					<div className="wallet-logo maiar">
						<a
							href=""
							style = {{ color: 'cyan' , fontSize: '1.5rem' }}
						>
							{/* <img src = {Maiar} alt="Maiar" /> */}
							MultiversX
						</a>
					</div>
				</div>
			</div>
			<div className="col-6">
				<div className="wallet" style = {{ cursor: 'pointer' }}>
					<div className="wallet-logo fiat">
						<a href="https://checkout.cryptocoin.pro/order/Clapart/nft/products/CLP_Connect_R?access=e6d63eb3-de8b-4f45-ae7d-75293f4cfa5b" target="_blank">Card</a>
					</div>
				</div>
			</div>
			{/* <div className = "col-6">
				<div className = "wallet">
					<div className = "wallet-logo metamask">
						<a href="#" target="_blank"><img src = {Metamask} alt="Metamask" /></a>
					</div>
				</div>
			</div>
			<div className = "col-6">
				<div className = "wallet">
					<div className = "wallet-logo coinbase">
						<a href="#" target="_blank"><img src = {CoinBase} alt="Coinbase" /></a>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export { Category };
