import { rarissimeTermsNConditions } from 'helpers/constant';
import React from 'react';
import { StaticInfo } from './StaticInfo';


const Terms = () => {
    return (
        <StaticInfo info = { rarissimeTermsNConditions }  />
    );
};


export {Terms};
