import React, { useState, useEffect } from 'react';

import png1 from '../../assets/images/press-1.png';
import png2 from '../../assets/images/press-2.png';
import png3 from '../../assets/images/press-3.png';
import png4 from '../../assets/images/press-4.png';
import png5 from '../../assets/images/press-5.png';


import partnerLogo from '../../assets/images/logos/HoodLogo.png';
import arcLogo from '../../assets/images/ARC.png';
import wam from '../../assets/images/wam.logo-white.png';
import sprintMusicSVG from '../../assets/images/logo-sprint-music.svg';
import outofspace from '../../assets/images/logos/OutOfSpaceLogo.png';
import ludowhite from '../../assets/images/ludo-white.png';
import ccpro from '../../assets/images/logos/ccproWhite.svg';
import binance from '../../assets/images/logos/BinanceLogo.wine.png';
import multiversx from '../../assets/images/logos/MultiversxLogo.png';

import { roadmaps, FAQ } from 'helpers/constant';
import { About } from './About';
import { Rarity } from './Rarity';
import { HolderBenefits } from './HolderBenefits';
import { StaticNumbers } from './StaticNumbers';
import { Roadmap } from './Roadmap';
import { Mission } from './Mission';
import { ClubRarissime } from './ClubRarissime';


import { text } from 'helpers/constant';

// const

// import {useNavigate} from 'react-router-dom';

const Main = () => {

    const [rarityStatus, setRarityStatus] = useState<string>('common');
    // const navigate = useNavigate();
    const [firstDate, setFirstDate] = useState(new Date().getTime());
    const [secondDate, setSecondDate] = useState(new Date(Date.parse('2023-04-07T20:00:00+03:00')).getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setFirstDate(firstDate => firstDate + 1000);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <main style={{ marginBottom: 32 }}>
            <div className="container collection-intro">



                {/*<div className="row list-title">*/}
                {/*    <div className="landing-title center">*/}
                {/*        <h2>How to buy NFTs</h2>*/}
                {/*    </div>*/}
                {/*    <div className="title-separator"></div>*/}
                {/*    <h4 style={{ textAlign: 'center' }}>How to buy using Multiversx</h4>*/}
                {/*    <div className="center width-80-text">*/}
                {/*        /!* {*/}
                {/*            text.map((item, index) => (*/}
                {/*                <p key={index}>*/}
                {/*                    {item}*/}
                {/*                </p>*/}
                {/*            ))*/}
                {/*        } *!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="row">*/}
                {/*    <div className="col-1 hidden-sm"></div>*/}
                {/*    <div className="col-10 col-12-sm">*/}
                {/*        /!* <video width="100%" height="auto" controls autoPlay muted>*/}
                {/*      <source src = 'https://youtu.be/xljW6eLXd7A' type="video/mp4" />*/}
                {/*    </video> *!/*/}
                {/*        <iframe*/}
                {/*            width='100%'*/}
                {/*            height='500px'*/}
                {/*            src='https://www.youtube.com/embed/xljW6eLXd7A'*/}
                {/*            frameBorder='0'*/}
                {/*            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'*/}
                {/*            allowFullScreen*/}
                {/*            title='Embedded youtube'*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="col-1 hidden-sm"></div>*/}
                {/*</div>*/}
                {/*<div className="row list-title">*/}
                {/*    <h4 style={{ textAlign: 'center' }}>How to buy using Fiat</h4>*/}
                {/*    <div className="center width-80-text">*/}
                {/*        /!* {*/}
                {/*            text.map((item, index) => (*/}
                {/*                <p key={index}>*/}
                {/*                    {item}*/}
                {/*                </p>*/}
                {/*            ))*/}
                {/*        } *!/*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="row">*/}
                {/*    <div className="col-1 hidden-sm"></div>*/}
                {/*    <div className="col-10 col-12-sm">*/}
                {/*        */}
                {/*        <iframe*/}
                {/*            width='100%'*/}
                {/*            height='500px'*/}
                {/*            src='https://www.youtube.com/embed/xljW6eLXd7A'*/}
                {/*            frameBorder='0'*/}
                {/*            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'*/}
                {/*            allowFullScreen*/}
                {/*            title='Embedded youtube'*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="col-1 hidden-sm"></div>*/}
                {/*</div>*/}


            </div>

            <About title='About' text={text.about} />
            <Mission />
            <Rarity setRarityStatus={setRarityStatus} />
            <HolderBenefits rarityStatus={rarityStatus} />
            <StaticNumbers />
            <Roadmap />
            <div className="container video-presentation">

                <div className="row">
                    <div className="col-1 hidden-sm"></div>
                    <div className="col-10 col-12-sm">
                        {/* <video width="100%" height="auto" controls autoPlay muted>
                      <source src = 'https://youtu.be/xljW6eLXd7A' type="video/mp4" />
                    </video> */}
                        <iframe
                            width='100%'
                            height='500px'
                            src='https://www.youtube.com/embed/xljW6eLXd7A'
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            title='Embedded youtube'
                        />
                    </div>
                    <div className="col-1 hidden-sm"></div>
                </div>

                <div className="row">
                    <div className="col-12 center">
                        <a href="https://discord.gg/ZBN4xD6MyQ" target="_blank" className="button button-huge invert-button call-to-action view-all">Join Discord Channel</a>
                        <a href="https://xoxno.com/collection/THH-224018" target="_blank" className="button button-huge call-to-action view-all" style={{
                            pointerEvents: (firstDate >= new Date(Date.parse('2023-03-01T00:00:00')).getTime()) && (firstDate < secondDate) ? 'none' : 'auto',
                            background: (firstDate >= new Date(Date.parse('2023-03-01T00:00:00')).getTime()) && (firstDate < secondDate) ? 'gray' : 'auto',
                            border: (firstDate >= new Date(Date.parse('2023-03-01T00:00:00')).getTime()) && (firstDate < secondDate) ? 'gray' : 'auto'
                        }} >Find us on XOXNO</a>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-12 center">*/}
                {/*        <a href=" https://form.typeform.com/to/stChVeIV" target="_blank" className="button button-huge invert-button call-to-action view-all wunset">Get on the Whitelist and get your Hood Hero NFT!</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className='row'>*/}
                {/*    <div className="center width-80-text">*/}
                {/*        Get on the Whitelist and receive the minting link 15 minutes before 20:00 on Launch Day (April 7th)*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
            <div className="container faq-list" id="faq">

                <div className="row list-title">
                    <div className="col-12 center">
                        <h2>FAQ</h2>
                    </div>
                </div>

                <div className="row">

                    {
                        FAQ.map((item, index) => (
                            <details key={index}>
                                <summary>{item.summary}</summary>
                                <p>{item.content}</p>
                            </details>
                        ))
                    }
                    <details className='summary'>
                        <summary>How do I get the unique discount code for educational content made available by Arc Academy partners?</summary>
                        <p>Step 1:</p>
                        <p>Send us a transaction worth 0 EGLD to @arcstake with the message "clapart" (blockchain network: MultiversX)</p>
                        <p>Step 2:</p>
                        <p>Email us at support@arcstake.com with your public address and you will receive an email containing the discount code.</p>
                        <p>Step 3:</p>
                        <p>Go to https://academy.arcstake.com and book the desired event, using the code received in the email. We will confirm your order by email.</p>
                        <p style={{ fontWeight: 'bold', margin: '20px 0' }}>Cum obțin codul unic de discount pentru conținutul educațional pus la dispoziție de partenerii Arc Academy?</p>
                        <p>Pasul 1:</p>
                        <p>Trimite-ne o tranzacție cu valoare 0 EGLD la adresa @arcstake cu mesajul „clapart” (rețea blockchain: MultiversX)</p>
                        <p>Pasul 2</p>
                        <p>Trimite-ne un e-mail la support@arcstake.com, împreună cu adresa ta publică și o să primești un mail ce conține codul de discount.</p>
                        <p>Pasul 3</p>
                        <p>Intră pe site-ul https://academy.arcstake.com și rezervă evenimentul dorit, folosind codul primit în e-mail. Îți vom confirma comanda printr-un e-mail.</p>
                    </details>
                    <details>
                        <summary>Who are we?</summary>
                        <p>Rarissime is a launchpad founded by the CLAPART team for the launch of exclusive collections of NFTs in the artistic area, mainly music, but not only. Rarissime's mission is to promote collections where the focus falls on the usefulness of the digital assets released for the community and investors, but also on the unique and exclusive artistic quality. For partnership proposals or any questions, you can use the contact form on this page or write to us at contact@clapart.com</p>

                        <p style={{ fontWeight: 'bold' }}>Cine suntem noi?</p>
                        <p>Rarissime este un launchpad fondat de echipa CLAPART pentru lansarea de colecții exclusive de NFTuri din zona artistică, cu precădere muzicală, dar nu numai. Misiunea Rarissime este să promoveze colecții unde accentul cade pe utilitatea activelor digitale lansate pentru comunitate și investitori, dar și pe calitatea artistică unică și exclusivă. Pentru propuneri de parteneriate sau orice întrebări, puteți utiliza formularul de contact din această pagină sau să ne scrieți pe contact@clapart.com</p>
                    </details>
                </div>

            </div>
            <ClubRarissime />
            <div className="row">
                <div className="col-12 center">
                    {/* <a href="#" className="button button-huge invert-button call-to-action view-all">Join Discord Channel</a> */}
                    <a href="https://discord.gg/ZBN4xD6MyQ" target="_blank" className="button button-huge call-to-action view-all">Join Club</a>
                </div>
            </div>
            {/* <div className="container press-list">

            <div className="row list-title">
                <div className="col-12 center">
                    <h2>Media Mentions</h2>
                </div>
            </div>

            <div className="row">

                <div className="roadmap-bg"></div>

                <div className="col-12">
                    <div className="press">
                        <img src = {png1} alt="" />
                        <img src = {png2} alt="" />
                        <img src = {png3} alt="" />
                        <img src = {png4} alt="" />
                        <img src = {png5} alt="" />
                    </div>
                </div>

            </div>

        </div> */}
            <div className="container press-list" id="partners">

                <div className="row list-title">
                    <div className="col-12 center">
                        <h2>Partners</h2>
                    </div>
                </div>

                <div className="row">

                <div className="press">
                    <a href='https://multiversx.com/' target="_blank">
                        <img src = {multiversx} width = {280} height = {280} alt="" />
                    </a>
                    <a href='https://www.binance.com/ro' target="_blank">
                        <img src = {binance} width = {280} height = {280} alt="" />
                    </a>
                </div>

                {/*<div className="roadmap-bg"></div>*/}

                <div className="col-12">
                    <div className="press">
                        <img src = {partnerLogo} width = {95} height = {95} alt="" />
                        <a href='https://arcstake.com/' target="_blank">
                            <img src = {arcLogo} width = {95} height = {95} alt="" />
                        </a>
                        <a href='https://wam.app/' target="_blank">
                            <img src = {wam} width = {95} height = {95} alt="" />
                        </a>
                        <a href='https://www.sprintmedia.ro/' target="_blank">
                            <img
                                src = {sprintMusicSVG}
                                width = {140} height = {63}
                                alt=""
                            />
                        </a>
                        <a href='http://www.outofspace.ro/' target="_blank">
                            <img src = {outofspace} alt="" width = {140} height = {63} />
                        </a>
                        <a href='https://ludo.com/' target="_blank">
                            <img src = {ludowhite} alt="" width = {140} height = {63}/>
                        </a>
                        <a href='https://www.cryptocoin.pro/' target="_blank">
                            <img src = {ccpro} alt="" width = {180} height = {83}/>
                        </a>
                    </div>
                </div>

                </div>

            </div>


        </main>
    );
};


export { Main };
