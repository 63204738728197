import React, { useState, useEffect } from 'react';
import {
    network,
    contractAddress,
    contractName,
    collectionId,
    conllectionName,
    decimals,
    fixed_precision,
    lastStage,
    limited_number_of_nfts
} from 'config';

import { AbiRegistry, Address, ArgSerializer, BigUIntValue, ContractFunction, ResultsParser, SmartContract, SmartContractAbi, TransactionPayload, TypedValue, U32Value } from '@multiversx/sdk-core';

import {
    ApiNetworkProvider
} from '@multiversx/sdk-network-providers';

import contractABI from 'assets/abi/clapart-mint-contract.abi.json';
import { useGetAccount, useGetNetworkConfig, useTrackTransactionStatus, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';


import { useNavigate } from 'react-router-dom';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';


import avatar from 'assets/images/user-avatar-small.jpg';
import unBoxedLogo from 'assets/images/unboxed-logo.jpg';


const Mint = () => {

    const navigate = useNavigate();

    const {
        address
    } = useGetAccount();

    const {
        network: {
            apiAddress
        }
    } = useGetNetworkConfig();

    const networkProvider = new ApiNetworkProvider(apiAddress);
    const [contractInteractor, setContractInteractor] = useState<any>(undefined);


    const [numberOfMints, setNumberOfMints] = useState<number>(1);
    const [error, setError] = useState<string>('');

    const [mintSessionID, setMintSessionID] = useState<string | null>(null);
    const [price, setPrice] = useState<number>(0);

    const [dropTokensLeft, setDropTokensLeft] = useState<number>(0);
    const [stage, setStage] = useState<number>(0);

    const { hasPendingTransactions } = useGetPendingTransactions();

    // const [mintSessionID , setMintSessionID] = useState<string | null>(null);
    // const [price , setPrice] = useState<number>(1);

    // const {hasPendingTransactions} = useGetPendingTransactions();

    const changeNumberOfMints = (e: any) => {
        if (e.target.value > 0) {
            setNumberOfMints(e.target.value.replace(/^0+/, ''));
        }
        else {
            setNumberOfMints(0);
        }
    };

    const isLoggedIn = Boolean(address);

    const mint = async () => {
        const args: TypedValue[] = [];
        args.push(new U32Value(numberOfMints));
        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`mint@${argumentsString}`);
        let gas = 20000000 + 15000000 * numberOfMints;
        gas = gas > 600000000? 600000000: gas;

        const tx = {
            receiver: contractAddress,
            gasLimit: gas,
            value: new BigUIntValue(Math.pow(10, decimals) * numberOfMints * price),
            data: data.toString()
        };

        await refreshAccount();
        const { sessionId } = await sendTransactions({
            transactions: tx,
            transactionsDisplayInfo: {
                processingMessage: 'Mint Transaction',
                errorMessage: 'An error has occured during mint',
                successMessage: 'Mint Successful'
            },
            redirectAfterSign: false
        });
        setMintSessionID(sessionId);
    };
    const transactionStatus = useTrackTransactionStatus({
        transactionId: mintSessionID,
        onSuccess: () => navigate('/mynft')
    });
    // useEffect(() => {
    //     if(mintSessionID){


    //     }
    // } , [mintSessionID])

    useEffect(() => {

        if (!isLoggedIn)
            navigate('/payment');
        const ABI = contractABI as any;
        (async () => {
            const abiRegistry = AbiRegistry.create(contractABI);
            const abi = new SmartContractAbi(abiRegistry, [
                contractName
            ]);
            const contract = new SmartContract({
                address: new Address(contractAddress),
                abi
            });

            setContractInteractor(contract);
        })();

    }, []);

    useEffect(() => {
        if (!isLoggedIn || !contractInteractor)
            return;

        (async () => {
            const query = contractInteractor.createQuery({
                func: new ContractFunction('getNftPrice'),
                args: [],
                caller: new Address(address)
            });

            const resultsParser = new ResultsParser();
            const response = await networkProvider.queryContract(query);
            const endpontDefinition = contractInteractor.getEndpoint('getNftPrice');
            const res = resultsParser.parseQueryResponse(
                response,
                endpontDefinition
            );
            const value = res.firstValue?.valueOf();
            setPrice(value.toNumber() / Math.pow(10, decimals));
        })();

        (async () => {
            const query = contractInteractor.createQuery({
                func: new ContractFunction('getDropTokensLeft'),
                args: [],
                caller: new Address(address)
            });

            const resultsParser = new ResultsParser();
            const response = await networkProvider.queryContract(query);
            const endpontDefinition = contractInteractor.getEndpoint('getDropTokensLeft');
            const res = resultsParser.parseQueryResponse(
                response,
                endpontDefinition
            );
            const value = res.firstValue?.valueOf();
            console.log(value);
            setDropTokensLeft(value.toNumber());
        })();

        (async () => {
            const query = contractInteractor.createQuery({
                func: new ContractFunction('getStage'),
                args: [],
                caller: new Address(address)
            });

            const resultsParser = new ResultsParser();
            const response = await networkProvider.queryContract(query);
            const endpontDefinition = contractInteractor.getEndpoint('getStage');
            const res = resultsParser.parseQueryResponse(
                response,
                endpontDefinition
            );
            const value = res.firstValue?.valueOf();
            setStage(value.toNumber());
        })();

    }, [contractInteractor, hasPendingTransactions]);

    // const getDropAmount = async () => {
    //     const contract = new SmartContract({address: new Address(contractAddress)});

    //     const query = contract.createQuery({
    //         func: new ContractFunction('getDropAmount'),
    //         args: [],
    //         caller: new Address(address)
    //     });

    //     const queryResponse = await networkProvider.queryContract(query);
    //     const resultsParser = new ResultsParser();

    //     const endpointDefinition: any =
    //         contractInteractor.getEndpoint('getDropAmount');
    //     const bundle = resultsParser.parseQueryResponse(
    //         queryResponse,
    //         endpointDefinition
    //     );

    //     const value = bundle.firstValue?.valueOf();
    //     console.log(value.toNumber());
    // };


    return (
        <main>
            <div className="container nft-page">

                <div className="row">

                    <div className="col-4 col-12-sm">

                        <div className="img-container">
                            <img
                                src={unBoxedLogo}
                                className="nft-image"
                                alt=""
                            />
                        </div>

                        <p className="nft-description">Discover the Connect-R’s Hood Heroes NFT you can receive and all its benefits.</p>

                    </div>

                    <div className="col-8 col-12-sm">
                        <div className="nft-page-info">

                            <h2 style={{ textAlign: 'center' }}>Connect-R’s Hood Heroes</h2>
                            {/* <small>NFT: {collectionId}</small>

							<div className ="mint-collection">
								<small>Collection: <a href="collection.html"><strong>{conllectionName}</strong></a></small>
							</div>

							<div className ="mint-tier">
								<small>Tier: <span className ="green"><strong>Common</strong></span></small>
							</div>

							<div className ="contract mint">
								<span className ="contract-label">Contract Address</span>
								<a href="#" target="_blank" className ="button contract">{contractAddress.slice(0 , 4)}...{contractAddress.slice(contractAddress.length -4 ,contractAddress.length)}</a>
								<span className ="fa fa-copy"></span>
							</div> */}
                            <div className='row'>
                                <div className="col-5 col-7-sm">
                                    <div className="user row">
                                        <div className="avatar left">
                                            <img src={avatar} alt="" />
                                            <div className="verified"><span className="fa fa-check green"></span></div>
                                        </div>
                                        <div className="user-info left">
                                            <p className="user-role">Created by</p>
                                            <h3 className="user-name">Connect-R</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="separator"></div>

                            <div className="row">

                                <div className="col-5 col-4-sm">
                                    <input type='number' max = {limited_number_of_nfts} value={numberOfMints} onChange={changeNumberOfMints} style={{
                                        // width : '60vw',
                                        display: 'block',
                                        margin: 'auto',
                                        // marginTop: '50px'
                                    }} />
                                    {
                                        error != '' ?
                                            <small style={{ color: 'red', display: 'block', width: '60vw', margin: 'auto' }} >{error}</small>
                                            : ''
                                    }
                                </div>

                                <div className="col-4 col-4-sm right">
                                    {
                                        price ? `${((price * numberOfMints).toPrecision(fixed_precision))} EGLD` : '???'
                                    }

                                    {/* <div className ="prices mint right"> */}
                                    {/* <p className ="price-1" style={{ marginTop: '0.6rem' , fontSize: '15px' }}>
											 claimable Tokens
										</p> */}
                                    {/* </div> */}
                                </div>

                                <div className="col-3 col-4-sm right">
                                    <button style={{
                                        // width: '60vw',
                                        display: 'block',
                                        margin: 'auto',
                                        marginTop: '0',
                                        // marginTop: '30px'
                                        pointerEvents: dropTokensLeft == 0 || numberOfMints > limited_number_of_nfts ? 'none' : 'auto',
                                        background: dropTokensLeft == 0 || numberOfMints > limited_number_of_nfts? 'gray' : 'var(--primaryGradient)',
                                    }}
                                        onClick={mint}
                                    >Buy
                                    </button>
                                </div>

                            </div>
                            <small style={{ color: 'red' }}>
                                {
                                    stage < lastStage ?
                                        dropTokensLeft == 0 ? `Drop ${stage} sold out. Be ready for the next Drop` :  numberOfMints > limited_number_of_nfts? 'only 50 NFTs can be minted each session': ''
                                        :
                                        dropTokensLeft == 0 ? 'Collection is sold out' : numberOfMints > limited_number_of_nfts? 'only 50 NFTs can be minted each session': ''
                                }
                            </small>

                            <div className="separator"></div>

                            <h3 className="offers-title">Holder Benefits</h3>
                            <p>Common</p>
                            <div className="nft-features">
                                <li>
                                    Earn rewards from the community pool, by completing epic quests
                                    <p>No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions</p>
                                </li>
                                <li>
                                    2% discount on all future Rarissime drops
                                    <p>Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners.</p>
                                </li>
                                <li>
                                    Access to our exclusive Discord community
                                    <p>Join our amazing community where we will connect and share a lot of goodies.</p>
                                </li>
                                <li>
                                    Priority access to future drops on CLAPART
                                    <p>New collections will follow and you will have priority in this future drops.</p>
                                </li>
                                <li>
                                    25% Discount in ARC Academy
                                    <p>25% discount when accessing courses in ARC Academy.</p>
                                </li>
                            </div>
                            <p>RARE</p>
                            <div className="nft-features">
                                <li>
                                    Earn rewards from the community pool, by completing epic quests
                                    <p>No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions</p>
                                </li>
                                <li>
                                    2% discount on all future Rarissime drops
                                    <p>Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners.</p>
                                </li>
                                <li>
                                    Access to our exclusive Discord community
                                    <p>Join our amazing community where we will connect and share a lot of goodies.</p>
                                </li>
                                <li>
                                    Priority access to future drops on CLAPART
                                    <p>New collections will follow and you will have priority in this future drops.</p>
                                </li>
                                <li>
                                    40% Discount in ARC Academy
                                    <p>40% discount when accessing courses in ARC Academy.</p>
                                </li>
                                <li>
                                    Access to the Gold Raffle
                                    <p>Win 100 FREE ticket "Ziua lui Stefan" concert.</p>
                                </li>
                            </div>

                            <p>EPIC</p>
                            <div className="nft-features">
                                <li>
                                    Earn rewards from the community pool, by completing epic quests
                                    <p>No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions</p>
                                </li>
                                <li>
                                    Access to the Platinum Raffle to WIN 1% of “Lasă-mă să te” song
                                    <p>Five Epic NFT holders will gain in a raffle (Platinum Raffle) five x 0.20% of the IP rights to “Lasă-mă să te” in perpetuity, with the corresponding future cashflow + Win 50 VIP PASSES to "Ziua lui Stefan" concert, including a meet and greet with Connect-R</p>
                                </li>
                                <li>
                                    2% discount on all future Rarissime drops
                                    <p>Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners.</p>
                                </li>
                                <li>
                                    Access to our exclusive Discord community
                                    <p>Join our amazing community where we will connect and share a lot of goodies.</p>
                                </li>
                                <li>
                                    Priority access to future drops on CLAPART
                                    <p>New collections will follow and you will have priority in this future drops.</p>
                                </li>
                                <li>
                                    50% Discount in ARC Academy
                                    <p>50% discount when accessing courses in ARC Academy.</p>
                                </li>
                            </div>
                            <p>LEGENDARY</p>
                            <div className="nft-features">
                                <li>
                                    Earn rewards from the community pool, by completing epic quests
                                    <p>No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions</p>
                                </li>
                                <li>
                                    Access to the Diamond Raffle to WIN 1% of “Vara nu dorm” song
                                    <p>Five Legendary NFT holders will gain in a raffle (Diamond Raffle) five x 0.20% of the IP rights to “Vara nu dorm” in perpetuity, with the corresponding future cashflow + Studio visit to Connect-R + 100% discount when accessing courses in ARC Academy</p>
                                </li>
                                <li>
                                    2% discount on all future Rarissime drops
                                    <p>Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners.</p>
                                </li>
                                <li>
                                    Access to our exclusive Discord community
                                    <p>Join our amazing community where we will connect and share a lot of goodies.</p>
                                </li>
                                <li>
                                    Priority access to future drops on CLAPART
                                    <p>New collections will follow and you will have priority in this future drops.</p>
                                </li>
                                <li>
                                    60% Discount in ARC Academy
                                    <p>60% discount when accessing courses in ARC Academy.</p>
                                </li>
                                <li>
                                    VIP PASS to "Ziua lui Stefan" concert
                                    <p>VIP PASS to "Ziua lui Stefan" concert</p>
                                </li>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </main>
    );
};

export {Mint};
