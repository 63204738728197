import { aboutRarissime } from 'helpers/constant';
import React from 'react';
import { StaticInfo } from './StaticInfo';


const AboutRarissime = () => {
    return (
        <StaticInfo info = { aboutRarissime }  />
    );
};


export {AboutRarissime};
