import { privacy } from 'helpers/constant';
import React from 'react';
import { StaticInfo } from './StaticInfo';


const Privacy = () => {
    return (
        <StaticInfo info = { privacy }  />
    );
};


export {Privacy};
