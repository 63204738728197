import React, { useEffect, useState } from 'react';
import {rarity} from 'helpers/constant';


import slider1 from '../../assets/images/slide-1.jpg';
import slider2 from '../../assets/images/slide-2.jpg';
import slider3 from '../../assets/images/slide-3.jpg';
import slider4 from '../../assets/images/slide-4.jpg';
import slider5 from '../../assets/images/slide-5.jpg';

interface HolderBenefitsInterface{
    rarityStatus: string
}

const HolderBenefits = ({
    rarityStatus
}: HolderBenefitsInterface) => {

    const [checkedId , setCheckedID] = useState(0);

    useEffect(() => {
        let interval:any;
        (async () => {
            interval = setInterval(() => {
                setCheckedID(prev => (prev + 1) % 5);
            } , 3000);
        })();
        return () => {
            clearInterval(interval);
        };
    } , []);
    return (
        <div className="container collection-features" id = 'holder_benefit'>

            <div className="row list-title">
                <div className="landing-title center">
                    <h2>Holder Benefits</h2>
                </div>
            </div>

            <div className="row">

                <div className="col-6 col-12-sm">
                    {
                        rarity.filter(item => item.title == rarityStatus)[0].utilities.map((item , index) => (
                            <details className = "feature" key = {index} open = {false}>
                                <summary>{item.header}</summary>
                                <p>{item.body}</p>
                            </details>
                        ))
                    }


                </div>

                <div className="col-6 col-12-sm">

                    <div id="nfts-slider">
                      <input type="radio" name="slider" id="s1" checked = {checkedId == 0} onClick = {() => setCheckedID(0)}/>
                      <input type="radio" name="slider" id="s2" checked = {checkedId == 1}  onClick = {() => setCheckedID(1)} />
                      <input type="radio" name="slider" id="s3" checked = {checkedId == 2}  onClick = {() => setCheckedID(2)} />
                      <input type="radio" name="slider" id="s4" checked = {checkedId == 3}  onClick = {() => setCheckedID(3)} />
                      <input type="radio" name="slider" id="s5" checked = {checkedId == 4}  onClick = {() => setCheckedID(4)} />
                      <label htmlFor="s1" id="slide1"><img src = { slider1 } alt="" /></label>
                      <label htmlFor="s2" id="slide2"><img src = { slider2 } alt="" /></label>
                      <label htmlFor="s3" id="slide3"><img src = { slider3 } alt="" /></label>
                      <label htmlFor="s4" id="slide4"><img src = { slider4 } alt="" /></label>
                      <label htmlFor="s5" id="slide5"><img src = { slider5 } alt="" /></label>
                      <div style={{display: 'table', clear: 'both'}}></div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export { HolderBenefits };
