export const text = {
    about : [
        'Introducing HOOD Heroes: a one-of-a-kind collection of avatars inspired by the vibrant life, full of transformations, of one of the most versatile voices in Romania - Connect-R.',
        'Developed in partnership with CLAPART - Rarissime, this exclusive collection provides fans with an opportunity to become Connect-R\'s partners and co-producers, \
        with ownership of a percentage of his famous evergreen, "Vara Nu dorm" a percentage of his NEW song, "Lasă-mă Să Te" as well as numerous other benefits and \
        drops.',
        'Blending blockchain technology, modern art, and music rights, Hood Heroes aims to \
        stimulate the artist-fan relationship, offering a unique glimpse into a one-of-a-kind \
        ecosystem where young talents will have the opportunity to become the rising stars of tomorrow.',
        'HOOD Heroes contains 5,000 characters, each offering a unique glimpse into the life and evolution of Connect-R. With a sharp focus on utility, \
        Hood Heroes boasts four rarity classes, ranging from Common to Rare, Epic, and Legendary. Each rarity opens a gate to VIP events, concerts, drops\
         and special quests. Embrace the extraordinary and immerse yourself in the transformative world of HOOD Heroes today!',
    ],
    mission: [
        'The mission of the Hood Heroes collection is to help set up an ecosystem where fans can become the partners of their artists, giving talents from underprivileged backgrounds a chance to become the stars of tomorrow.'
    ],
};

export const rarity = [
    {
        title: 'common',
        color: 'green',
        nfts: 4000,
        describe : [
                'Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue',
                '2% discount on all future Rarissime drops ',
                'Access to our exclusive Discord community ',
                'Priority access to future drops on CLAPART',
                '25% Discount in ARC Academy'
            ],
        utilities: [
            {
                header: 'Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue',
                body: 'No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions!'
            },
            {
                header: '2% discount on all future Rarissime drops',
                body: 'Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners'
            },
            {
                header: 'Access to our exclusive Discord community',
                body: 'Join our amazing community where we will connect and share a lot of goodies'
            },
            {
                header: 'Priority access to future drops on CLAPART',
                body: 'New collections will follow and you will have priority in this future drops'
            },
            {
                header: '25% Discount in ARC Academy',
                body: '25% discount when accessing courses in ARC Academy'
            },
        ]
    },
    {
        title: 'rare',
        color: 'purple',
        nfts: 750,
        describe : ['Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue' ,
                    '2% discount on all future Rarissime drops',
                    'Access to our exclusive Discord community',
                    'Priority access to future drops on CLAPART',
                    '40% Discount in ARC Academy',
                    'Access to the Gold Raffle',
                ],
        utilities: [
            {
                header: 'Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue',
                body: 'No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions!'
            },
            {
                header: '2% discount on all future Rarissime drops',
                body: 'Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners'
            },
            {
                header: 'Access to our exclusive Discord community',
                body: 'Join our amazing community where we will connect and share a lot of goodies'
            },
            {
                header: 'Priority access to future drops on CLAPART',
                body: 'New collections will follow and you will have priority in this future drops'
            },
            {
                header: '40% Discount in ARC Academy',
                body: '40% discount when accessing courses in ARC Academy'
            },
            {
                header: 'Access to the Gold Raffle',
                body: 'Win 100 FREE ticket "Ziua lui Stefan" concert'
            },
        ]
    },
    {
        title: 'epic',
        color: 'orange',
        nfts: 200,
        describe : ['Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue',
                    'Access to the Platinum Raffle to WIN 1% of “Lasă-mă să te” song',
                    '2% discount on all future Rarissime drops',
                    'Access to our exclusive Discord community',
                    'Priority access to future drops on CLAPART',
                    '50% Discount in ARC Academy',
                ],
        utilities: [
            {
                header: 'Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue',
                body: 'No NFT staking required! You can trade your NFT as you desire, but don’t miss out on the monthly distributions!'
            },
            {
                header: 'Access to the Platinum Raffle to WIN 1% of “Lasă-mă să te” song',
                body: 'Five Epic NFT holders will gain in a raffle (Platinum Raffle) five x 0.20% of the IP rights to “Lasă-mă să te” in perpetuity, with the corresponding future cashflow + Win 50 VIP PASSES to "Ziua lui Stefan" concert, including a meet and greet with Connect-R'
            },
            {
                header: '2% discount on all future Rarissime drops',
                body: 'Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners'
            },
            {
                header: 'Access to our exclusive Discord community',
                body: 'Join our amazing community where we will connect and share a lot of goodies'
            },
            {
                header: 'Priority access to future drops on CLAPART',
                body: 'New collections will follow and you will have priority in this future drops'
            },
            {
                header: '50% Discount in ARC Academy',
                body: '50% discount when accessing courses in ARC Academy'
            },
        ]
    },
    {
        title: 'legendary',
        color: 'red',
        nfts: 50,
        describe : [
                    'Guaranteed access for two years to a pool that offers back to the community 10% APY from the total sales revenue',
                    'Access to the Diamond Raffle to WIN 1% of “Vara nu dorm” song',
                    '2% discount on all future Rarissime drops',
                    'Access to our exclusive Discord community',
                    'Priority access to future drops on CLAPART',
                    '60% Discount in ARC Academy',
                    'VIP PASS to "Ziua lui Stefan" concert',
                ],
        utilities: [
            {
                header: 'Earn rewards from the community pool, by completing epic quests',
                body: 'No NFT staking required! You can trade your NFT as you desire, but don\'t miss the chance to claim the monthly rewards!'
            },
            {
                header: 'Access to the Diamond Raffle to WIN 1% of “Vara nu dorm” song',
                body: 'Five Legendary NFT holders will gain in a raffle (Diamond Raffle) five x 0.20% of the IP rights to “Vara nu dorm” in perpetuity, with the corresponding future cashflow + Studio visit to Connect-R + 60% discount when accessing courses in ARC Academy'
            },
            {
                header: '2% discount on all future Rarissime drops',
                body: 'Future discount will be granted only to people still holding the NFT at the moment of the future drop, not to previous owners'
            },
            {
                header: 'Access to our exclusive Discord community',
                body: 'Join our amazing community where we will connect and share a lot of goodies'
            },
            {
                header: 'Priority access to future drops on CLAPART',
                body: 'New collections will follow and you will have priority in this future drops'
            },
            {
                header: '60% Discount in ARC Academy',
                body: '60% discount when accessing courses in ARC Academy'
            },
            {
                header: 'VIP PASS to "Ziua lui Stefan" concert',
                body: 'All Legendary NFT holders will receive a Free VIP PASS to "Ziua lui Stefan" concert'
            },
        ]
    }
];


export const staticsNumAndDes = [
    {
        num: 5000,
        unit: '',
        desc: 'NFT’s'
    },
    {
        num: 1,
        unit: '%',
        desc: 'From “Vara nu dorm” and "Lasă-mă să te" songs'
    },
];


export const roadmaps = [
    {
        phaseI: 'Phase 01',
        date: '7 March - 13 April, 2023',
        finished: true,
        events: [
            '7 March - 6 April - Marketing, promotion, AMAs and podcasts',
            '6 April - Pre-minting event',
            '7 April - First Drop of 1,000 NFTs minted on MultiversX at 20:00 - mint price 1.3 Egld',
            '9 April - Second Drop of 2,000 NFTs minted on MultiversX at 20:00 - mint price 1.6 Egld',
            'TBA - Third Drop of 2,000 NFTs minted on MultiversX - mint price 1.9 Egld',
            'Until 23 April - Raffles for benefits (Gold Raffle, Platinum Raffle and Diamond Raffle)'
        ]
    },
    {
        phaseI: 'Phase 02',
        date: '14 April – 31 December, 2023',
        finished: true,
        events: [
            'Q2 - Free tickets and VIP passes for "Ziua lui Stefan" distributed',
            'Royalty distribution',
            'Start work on Social Mission'
        ]
    },
    {
        phaseI: 'Phase 03',
        date: 'After 2023',
        finished: true,
        events: [
            'Royalty distribution',
            'Continue work on Social Mission'
        ]
    },
];

export const FAQ = [
    {
        summary: 'What is an NFT?',
        content: 'An NFT (Non-Fungible Token) is a unique digital asset that represents ownership of a specific item or piece of content on a blockchain network, such as artwork, music, videos, etc. NFTs are stored on a blockchain and are cryptographically verified, making them resistant to duplication or fraud.'
    },
    {
        summary: 'How can I buy a Hood Heroes NFT?',
        content: 'The accepted payment methods at the moment (we may include other methods in the future) are EGLD (via xPortal digital wallet) or direct card payment, through the CryptoCoinPro payment processor, directly integrated into our sales page.'
    },
    {
        summary: 'What is an e-wallet?',
        content: 'A cryptocurrency wallet (e-wallet) is a software program that stores private and public keys and interacts with various blockchains to allow users to send and receive cryptocurrencies and monitor their balance.'
    },
    {
        summary: 'What does minting an NFT mean?',
        content: 'Minting of an NFT (Non-Fungible Token) refers to the process of creating and registering a unique digital token on a blockchain platform such as MultiverseX, Ethereum or Binance Smart Chain. This process involves creating a unique cryptographic code that contains information about the owner, creator, and specific characteristics of the NFT, such as the image or associated digital content.'
    },
    {
        summary: 'How do I access my Hood Heroes NFTs?',
        content: 'Hood Heroes NFTs will be available in the e-wallet used to purchase them, whether it is the xPortal wallet or the custodian wallet provided by CryptoCoinPro.'
    },
    {
        summary: 'What can I do with my NFTs?',
        content: 'Hood Heroes NFTs offer a wide range of utilities, as also indicated in the section on the page above. These include the right to 10% guaranteed annual return (on the purchase value in EGLD, for two years), access to concerts, access to exclusive communities, as well as participation in special raffles (Gold, Platinum and Diamond), which offer unique prizes, such as VIP passes, visits to the artist\'s studio or the possibility of becoming a co-owner of famous pieces, such as “Vara nu dorm” and “Lasă-mă să te” . NFTs can also be traded on any secondary trading market such as as Xoxno, Deadreare, Eneftor or Isengard.'
    },
    {
        summary: 'What if the Hood Heroes NFTs are all sold?',
        content: 'Rarissime and Connect-R are offering 5000 unique NFTs for sale. If these are all sold out, you will be able to purchase the NFTs on secondary trading markets, such as Xoxno, Deadreare, Eneftor or Isengard.'
    },
    {
        summary: 'How can I claim the rewards from the 10% community pool?',
        content: 'We will offer exciting new quests each month, in which you will have the chance to earn substantial rewards. The quests will be announced on our Club Rarissime exclusive Discord community (With access only for NFT holders). The monthly distribution from the 10% APY pool will be rewarded ONLY to the NFT owners that take part in the quests. A total of 20% of the collection mint revenue will be distributed through the community pool over two years.'
    },



    {
        summary: 'What is gas on the blockchain?',
        content: 'Blockchain gas refers to the unit of measure of resources required to perform a transaction or operation on a blockchain platform such as Ethereum, MultiversX or Binance Smart Chain.\
            Gas can be thought of as a fee paid by blockchain users to perform transactions or operations. The more gas a transaction requires, the more complex or computationally demanding it is, and the more likely it is to be processed more slowly.\
            Paying for gas ensures that users cannot overload the capacity of the blockchain network and prevents cyber-attacks or spam. Gas can be paid in cryptocurrencies compatible with the blockchain network and can vary depending on the demand and supply of available processing capacity at any given time'
    },
    {
        summary: 'What is a blockchain?',
        content: 'Blockchain is a decentralized information storage and transfer technology that uses a distributed network of nodes to record and validate transactions without the need for a centralized intermediary.\
            This consists of a digital, public and secure ledger where transactions are recorded in blocks that are linked together by cryptography. These blocks are stored on multiple nodes, which makes it impossible to change the information recorded in the blockchain, thus ensuring the safety and transparency of transactions.\
            Blockchain is used in various fields such as cryptocurrencies, money transfers, NFTs, e-voting, supply chain tracking and many more because it provides a secure and efficient solution for recording and transferring information transparently and decentralized.'
    },
    {
        summary: 'What is Web 3?',
        content: 'Web 3 refers to a new phase of Internet development, which is based on blockchain technologies and decentralization. Web 3 aims to provide a freer and more democratic Internet that allows users to own and control their own data and information without the need for centralized intermediaries.\
            Web 3 brings a number of technological innovations, such as blockchain networks, cryptography, smart contracts and tokenization, which enable the creation of decentralized applications and services that can be used efficiently and securely by users. Web 3 has the potential to completely transform the way users interact with the Internet, from financial services and e-commerce to e-voting and decentralized governance.'
    },
    // {
    //     summary: 'How do I get the unique discount code for educational content made available by Arc Academy partners?',
    //     content: 'Step 1: \
    //     Send us a transaction worth 0 EGLD to @arcstake with the message "clapart" (blockchain network: MultiversX) \n \
    //     Step 2:\
    //     Email us at support@arcstake.com with your public address and you will receive an email containing the discount code.\
    //     Step 3:\
    //     Go to https://academy.arcstake.com and book the desired event, using the code received in the email. We will confirm your order by email.\
    //     Cum obțin codul unic de discount pentru conținutul educațional pus la dispoziție de partenerii Arc Academy?\
    //     Pasul 1:\
    //     Trimite-ne o tranzacție cu valoare 0 EGLD la adresa @arcstake cu mesajul „clapart” (rețea blockchain: MultiversX)\
    //     Pasul 2\
    //     Trimite-ne un e-mail la support@arcstake.com, împreună cu adresa ta publică și o să primești un mail ce conține codul de discount.\
    //     Pasul 3\
    //     Intră pe site-ul https://academy.arcstake.com și rezervă evenimentul dorit, folosind codul primit în e-mail. Îți vom confirma comanda printr-un e-mail.',
        // content1 : [
        //     {
        //         title: 'Step 1:',
        //         body: 'Send us a transaction worth 0 EGLD to @arcstake with the message "clapart" (blockchain network: MultiversX)'
        //     },
        //     {
        //         title: 'Step 2:',
        //         body: 'Email us at support@arcstake.com with your public address and you will receive an email containing the discount code.'
        //     },
        //     {
        //         title: 'Step 3:',
        //         body: 'Go to https://academy.arcstake.com and book the desired event, using the code received in the email. We will confirm your order by email.'
        //     }
        // ],
        // content2 : [
        //     {
        //         title: 'Step 1:',
        //         body: 'Send us a transaction worth 0 EGLD to @arcstake with the message "clapart" (blockchain network: MultiversX)'
        //     },
        //     {
        //         title: 'Step 2:',
        //         body: 'Email us at support@arcstake.com with your public address and you will receive an email containing the discount code.'
        //     },
        //     {
        //         title: 'Step 3:',
        //         body: 'Go to https://academy.arcstake.com and book the desired event, using the code received in the email. We will confirm your order by email.'
        //     }
        // ]
    // },
    // {
    //     summary: 'Who are we?',
    //     content: 'Rarissime is a launchpad founded by the CLAPART team for the launch of exclusive collections of NFTs in the artistic area, mainly music, but not only. Rarissime\'s mission is to promote collections where the focus falls on the usefulness of the digital assets released for the community and investors, but also on the unique and exclusive artistic quality. For partnership proposals or any questions, you can use the contact form on this page or write to us at contact@clapart.com\
    //     Cine suntem noi?\
    //     Rarissime este un launchpad fondat de echipa CLAPART pentru lansarea de colecții exclusive de NFTuri din zona artistică, cu precădere muzicală, dar nu numai. Misiunea Rarissime este să promoveze colecții unde accentul cade pe utilitatea activelor digitale lansate pentru comunitate și investitori, dar și pe calitatea artistică unică și exclusivă. Pentru propuneri de parteneriate sau orice întrebări, puteți utiliza formularul de contact din această pagină sau să ne scrieți pe contact@clapart.com'
    // },
];

export const aboutRarissime = {
    header: 'ABOUT Rarissime',
    description: 'Just like NFTs are much more than digital graphics, so is Rarissime much more than a standard NFT mint/markeplace. \
     Our NFTs are complex smart contracts that, besides a graphic collectible, deliver real-world, off-chain benefits.  \
    Such benefits gravitate around a constellation of superstars (musicians, actors, athletes, influencers) that YOU can \
     interact with. YOU can be a super-fan. Technology makes it possible to collect, store and trade access to the superstars. \
    Rarissime is the premium NFT brand of CLAPART. The name, denoting rarity and sophistication, stands for the exclusive range of super-fan content investors can access.\
    With a robust yet flexible multi-chain architecture, Rarissime is designed for a smooth art appreciation experience. Enjoy the moments with your idol, we take care of the rest. \
    Rarissime...anything but common.',
    contents: []
};

export const rarissimeTermsNConditions = {
    header: 'CLAPART RARISSIME TERMS AND CONDITIONS',
    description: 'THE AGREEMENT BELOW SETS OUT THE TERMS & CONDITIONS WHICH GOVERN YOUR USE OF OUR SERVICES \
        These Rarissime Terms and Conditions are entered into between you (hereinafter referred to as “you” or “your”) \
        and Rarissime, a CLAPART brand, telling you about the rules for using our webpage www.rarissime.clapart.com and \
        www.connectR.clapart.com (hereinafter referred to as “Webpage”). Please note that our Webpage is just a presentation \
        of services that are will be provided within the CLAPART ecosystem, such as a NFT collections, a NFT Marketplace, CLP \
        tokens, staking and many more (hereinafter referred to as “Services”). Thus, please be advised that the terms and conditions \
        of using those Services will be provided therein. By using (in any possible way) our Services, you fully agree that you have \
        read, understood, and accepted all the terms and conditions stipulated in these Terms and Conditions (hereinafter referred to as ‘’Terms’’). \
        Please be aware that accessing or using any services accessible through external links displayed on the Webpage are not covered by these Terms. \
        Please carefully read all terms that are applicable to those services before accessing the third-party’s services. If you do not accept these Terms, \
        do not access our Webpage and its Services. These Terms apply and complete all Rarissime\'s agreements that expressly refer to them, in the limits \
        and the terms established by those agreements. In case of any conflict between CLAPART\'s agreements and these Terms, the CLAPART agreements \
        should prevail unless the parties have agreed otherwise. Accessing and using in any way our Services available on the Webpage you fully acknowledge \
        to be bounded by these Terms. Any reference to our Services also applies to your use of the Webpage. \
        These Terms constitute a legal agreement and create a binding contract between you and Rarissime. ',
    contents : [
        {
            subtitle: '1.Rarissime Overview',
            subcontent: 'Rarissime is an exclusive NFT mint and marketplace developed by CLAPART, \
                which focuses on utility NFT collections featuring the CLAPART artists. Rarissime uses blockchain technology \
                (multi-chain minting) and the CLP token. Our Webpage is a beta technical infrastructure which must be seen \
                only as an online beta tool for presenting and pre-testing (if applicable) our Services. We offer no guarantee \
                or assurance of the function ability of the Webpage and/or its Services. Rarissime does not commit and shall not \
                be liable for any failure to support, display or offer any of the features available within the Webpage, especially \
                but not limited to the situation in which a damage is caused by the action of a third party. To contact us, please \
                email at legal.rarissime@clapart.com. '
        },
        {
            subtitle: '2.Definitions (a-z)',
            subcontent: '”Authority” means any statutory governmental, judicial, or other authority or any of them or any of their authorized representatives;\
                ”Cases of force majeure” represents extraordinary events or circumstances which neither Party could have foreseen or prevented by reasonable means,\
                including but not limited to natural disasters, war, revolution, uprising civil insurrection, acts of terrorism, expropriation, nationalization,\
                nuclear explosion, radioactive or chemical contamination or ionizing radiation, except lack of funds, which will not be a Force Majeure Case;\
                ”CLP Tokens” means the tokens (Cryptocurrency) issued by CLAPART, the parent of Rarissime, using the Binance blockchain infrastructure; \
                ”Rarissime” means the group of the companies which are developing the Rarissime ecosystem, including the owner and the administrator of the\
                Webpage, the software development company, the token issuer and administrator;\
                ”Rarissime Technical Partners (TECHPARTs) ” means the service providers used to offer different\
                features to our users, in connection with the Webpage or in connection with the CLP Tokens and/or other cryptocurrencies;\
                ”Services” refers to all features, functionalities, utilities, use cases and actions, of any kind, available exclusively on the Webpage;\
                ”User” any natural or legal person who decided to use our Services; \
                ”Visitor” means any person who accesses the Webpage without being registered; \
                ”Webpage” means the webpage www.rarissime.clapart.com and www.connectR.clapart.com through which we present our Services;'
            },
        {
            subtitle: '3.General provisions ',
            subcontent: 'WE DO NOT OFFER ANY NFT OR CRYPTOINVESTMENT OR FINANCIAL ADVICE. ANY REFERENCE TO INVESTMENTS \
                DISPLAYED ON THE WEBPAGE IS MENTIONED ONLY FOR INFORMATIVE PURPOSES.\
                Rarissime does not provide NFT or cryptocurrency investment or consulting\
                advice of any kind and is not responsible for the use or interpretation of\
                information available on the Webpage (regardless the uploader) or any other communication method.\
                Any approximation, value or estimation available on the Webpage is pure for informative or explanation\
                purposes and do not constitute a promise or guarantee. All Rarissime Services are available only to persons who are in full civil\
                capacity according to their national regulation. Rarissime cannot and does not guarantee the Services (including the content of\
                    the Webpage) accuracy, applicability, reliability, integrity, performance, or appropriateness. Rarissime shall not be liable\
                    for any loss or damage that may be caused directly or indirectly by your use of these contents. All users MUST UNDERSTAND AND\
                    ASSUME the risks associated with the use (regardless the method) of Cryptocurrencies/Digital Assets, and it is recommended to\
                        exercise a high level of prudence and responsibly assume decisions within their own capabilities, on their own risk. \
                PLEASE TAKE INTO CONSIDERATION THAT THE VALUE OF THE CLP TOKENS AND ANY OTHER CRYPTOCURRENCY ON THE OPEN MARKET MAY CHANGE BY\
                +/- 100% EVERY SECOND BY REFERENCE TO THE ACQUISITION PRICE OR BY REFERENCE TO ANY PREVIOUS VALUE. '
        },
        {
            subtitle: '4.Webpage Functionality',
            subcontent: 'In some unfortunate cases or for maintenance purposes, it will be to suspend or withdraw the Webpage for an undefined period of time.\
             We do not guarantee that our Webpage, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict\
              the availability of all or any part of our Webpage for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal,\
               if possible. You are also responsible for ensuring that all persons who access our Webpage through your internet connection are aware of these terms of use and\
                other applicable terms and conditions, and that they comply with them.'
        },
        {
            subtitle: '5.Prohibition of Use and Commitments',
            subcontent: 'Depending on your country of residence, incorporation, or registered office, you may not be able to access or use any of the Rarissime Services.\
             It is your responsibility to follow the rules and laws in your country of residence and/or country from which you access this Webpage. By accessing and using the Services\
             , you represent and warrant that you have not been included (directly/indirectly) and has no relationship (directly/indirectly) with any person whose citizen or resident\
              of a country which is/was included in any trade embargoes or economic sanctions list (such as the United Nations Security Council sanctions list), the list of specially\
               designated nationals maintained by OFAC (The Office of Foreign Assets Control of the U.S. Department of the Treasury), the Financial Action Task Force (FATF),\
                International Traffic In Arms Regulations (ITAR) or the denied persons or entity list of the U.S. Department of Commerce. The restrictions herein target the inclusion\
                 as a natural/legal person on the above-mentioned lists as well as the inclusion of the User’s citizenship or residency state. Rarissime reserves the right to choose\
                  markets and jurisdictions to conduct business, and may restrict or refuse, in its discretion, the provision of Rarissime services in certain countries or regions.'
        },
        {
            subtitle: '6.Licensing',
            subcontent: 'Subject to the fulfilment of the foregoing Terms, Rarissime grants you a revocable, limited rights, royalty-free, non-exclusive, non-transferable, \
            and non-sublicensable license ONLY to access and use Rarissime Services through your computer or Internet compatible devices for your personal/internal purposes. \
            You are prohibited to use the Services for resale or commercial purposes, including operations on behalf of other persons or entities. All the above actions are expressly\
             prohibited and constitute a material violation of these Terms. The content layout, format, function, and access rights regarding the Services should be stipulated\
              in the discretion of Rarissime. Rarissime reserves all rights not expressly granted in these Terms. Therefore, you are hereby prohibited from using our Services\
               in any way not expressly authorized by these Terms. To avoid any misunderstanding these Terms only grant a limited license to access and use Rarissime Services.\
                Therefore, you hereby agree that when you use our Services, we do not transfer our Services or the ownership or intellectual property rights of any intellectual\
                 property to you or anyone else. All content published, all the text, graphics, visual interface, photos, sounds, process flow diagrams, computer code (including html\
                     code), programs, software, products, information and documents, as well as the design, structure, selection, coordination, expression, look and feel, and layout\
                      of any content included in the Services or provided as a part of our Services, are exclusively owned, controlled and/or licensed by Rarissime or its members,\
                       parent companies, licensors or affiliates. Rarissime owns any feedback, suggestions, ideas, or other information or materials (hereinafter collectively referred\
                         to as “Feedback”) about the Services that you provide through any means of communication. You hereby transfer all rights, ownership and interests of the\
                          Feedback and all related intellectual property rights to us. You have no right and hereby waive any request for acknowledgment or compensation based\
                           on any Feedback, or any modifications based on any Feedback.'
        },
        {
            subtittle: '7.CLP Token General Provisions',
            subcontent: 'Each user understands and fully assumes that, (i) CLP has been issued and deployed on Binance (BNB Chain), (ii) at the date of these Terms, \
            CLP is not listed on any DEX or CEX, (iii) any third party, including the token issuer may try to establish partnerships with different technical infrastructures \
            for implementing use cases for the CLP Token, such us accessing or acquiring services and features available on the webpage or any other third-party Webpage, (iv\
                ) Rarissime does not assume and guarantee any value/specific functionality/utility of the token (v) Buying/acquiring CLP Tokens do not involve the purchase\
                 of shares, securities exchangeable into shares or any equivalent in any existing or future public or private company, corporation, or other entity in any\
                  jurisdiction, including but not limited to the Webpage. CLP tokens are not qualified as financial instruments and are not similarly approved by any authority\
                   in the world. To avoid any misunderstanding, obtaining CLP Tokens do not offer to the buyer any right, including but not limited to any right to receive\
                   , exercise and, or request something in connection or from any company/ activity/revenues/ profits or any other right which can qualify it as security. '
        },
        {
            subtitle: '8.CLP Market Value and the NFTs',
            subcontent: 'Any value displayed on the Webpage is only for reference purposes and under no circumstances shall be interpreted as a promise or that Rarissime Project\
             is liable or responsible for that value. We strongly recommend you not to rely on any value indicated in the Webpage or the Marketplace, and you should do your own\
              calculation to have an accurate representation of your asset’s value. '
        },
        {
            subtitle: '9.Rarissime Technical Partners (TECHPARTs)',
            subcontent: 'Sometimes Rarissime’s Services may be available through Rarissime Technical Partners (TECHPART)’s webpages or independent third-party webpages, \
            situations in which their specific rules, restrictions or terms & conditions will be fully applicable for those features. Services may contain links\
             to third-party webpages or services that are not owned or controlled by the Rarissime. Additionally, the Services may allow you to interact directly\
              with services provided by third parties without leaving our Webpage. Rarissime shall not be liable for the actions, inactions, or any activity performed\
               by any TECHPART’s or third party who decided to use/integrate Rarissime’s Services or CLP Tokens. Rarissime has no control over, and assumes no responsibility\
                for, the content, privacy policies, or practices of any third-party web pages or services. You further acknowledge and agree that the Rarissime shall not be\
                 responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such\
                  content, goods or services available on or through any such webpages or services. We strongly advise you to read the terms and conditions and privacy policies\
                   of any third-party web pages or services that you visit. We reserve the right to terminate or suspend access to these third-party services immediately\
                   , without prior notice or liability, for any reason whatsoever. Under no circumstances should Rarissime be considered liable or a party to any business\
                    relationship/ Agreement concluded between any person and the TECHPART/third party providing services in connection with Rarissime’s Services or CLP Tokens\
                    , even those services, the TECHPART/third party is promoted or displayed on the Webpage. '
        },
        {
            subtitle: '10.Campaigns',
            subcontent: 'Rarissime may propose or accept to organize different Campaigns for different purposes, such as marketing Campaigns\
             (Giveaways, Airdrops, Bounty) or economic Campaigns for supporting the integrity of the ecosystem’s economy (Locking token Campaigns).\
              Campaigns may be directly organized through the Webpage, where those Terms and other specific Policies will be applicable, or through third party’s\
               services, where the Campaigns will be fully governed by their terms & conditions. Rarissime is not responsible to any Campaign which is not directly\
                and fully performed on the Webpage’s domain, even that those Campaigns are displayed on the Webpage or can be accessed through external links/hyperlinks\
                 available on the Webpage. All announcement and/or banners available on the Webpage are only for marketing purposes, so please follow the service provider’s\
                  webpage to discover all the terms and conditions of the Campaign. Rarissime does not warrant or assume any liability in connection with the service provider’s\
                   actions, promises, content and services.'
        },
        {
            subtitle: '11.Content Reliance',
            subcontent: 'The content on our page is provided for general information only.\
             It is not intended to amount to advice on which you should rely. You must obtain professional\
              or specialist advice before taking, or refraining from, any action based on the content published on the Webpage.\
               Although we make reasonable efforts to update the information on our page, we make no representations, warranties or guarantees,\
                whether express or implied, that the content posted by us on our Webpage is accurate, complete, or up to date. Under no circumstances, should Ads,\
                 articles, or any other advertisement available on the Webpage be construed as a Rarissime’s mandatory bid (business offer). All information available\
                  on the Webpage should be interpreted as a commercial presentation. Please note that different rules and policies may be applicable for each feature or\
                   even for the same future if it is provided by different third parties.'
        },
        {
            subtitle: '12.Verifications',
            subcontent: 'To comply with all applicable regulations, in some cases, Rarissime may need to verify the User\'s identity for using specific Services, \
            if necessary. You agree and Rarissime may require Users, in its discretion, to prove their identity by providing the personal information or documents\
             such as, but not limited to national ID, passport, driving license, live video, proof of funds, proof of residence (utility bill) and so on. For example,\
              that information is mandatory in order to verify Users’ identity, identify traces of money laundering, terrorist financing, fraud, and other financial crimes.\
               When providing the required information, you confirm it is true and accurate. \
            When applicable, all personal data including electronic wallet address will be collected, processed and stored in accordance with the Privacy Policy displayed in the\
             corresponding section of the Webpage.\
            During the analysis or in case of failing to comply with Rarissime’s request necessary to verify the user’s identity and the source of funds,\
             (if applicable) Rarissime is entitled to block your access to our Services or assets (if applicable) until it is satisfied with the information\
              you have provided and determine in its sole discretion that it is sufficient to validate your KYC/AML procedure. In the meantime, you will\
               not be allowed to perform any action in connection with you, Services or assets (if applicable) or to request the deletion of the Personal\
                Data processed during verification operations.\
            IF THERE ARE ANY GROUNDS FOR BELIEVING THAT ANY OF THE INFORMATION YOU PROVIDED IS INCORRECT, FALSE, OUTDATED, OR INCOMPLETE, RARISSIME RESERVES THE RIGHT TO SEND YOU\
             A NOTICE TO DEMAND CORRECTION, DIRECTLY DELETE THE RELEVANT INFORMATION, AND, AS THE CASE MAY BE, TERMINATE ALL OR PART OF RARISSIME SERVICES WE PROVIDE FOR YOU. IF WE ARE\
              UNABLE TO REACH YOU WITH THE CONTACT INFORMATION YOU PROVIDED, YOU SHALL BE FULLY LIABLE FOR ANY LOSS OR EXPENSE CAUSED TO RARISSIME DURING YOUR USE OF OUR SERVICES.\
            In case Rarissime will decide to block your activity, all your assets will be administrated in accordance with the applicable laws.'
        },
        {
            subtitle: '13.Cybersecurity',
            subcontent: 'You are responsible for all activity that happens on or through ANY of your Wallets/accounts, even that in those accounts (of any kind) are stored NFTs, \
            CLP Tokens or the other Cryptocurrencies. Rarissime cannot take any action (such as, but not limited to transfer, undo a transfer, recover access, and so on) in connection \
            with any account/wallet in which you store NFTs or CLP Tokens. Considering the aspects mentioned above, you agree that you are the only one responsible for: \
            osetting up and keeping your all your credentials confidential;\
            oaccessing and using the selected recovery method/the supplementary security measures;\
            oany action or transfer you or any other person perform in connection with your assets/accounts. \
            Any action on the Webpage, is considered to have been made by you and irrevocable once performed. You are required\
             to notify Rarissime immediately of any unauthorized use of your device which was used to access the Webpage, or any\
              other breach of security, by email to legal.rarissime@clapart.com.\
            You are solely responsible for keeping safe of your accounts/wallets and devices. Rarissime assumes no liability for any loss\
             or consequences caused by authorized or unauthorized use of your account and/or credentials, including but not limited to access\
              caused by information disclosure, hacking, information release, phishing, phishing, and so on.'
        }
    ]
};

 export const privacy = {
    header: 'CLAPART RARISSIME PRIVACY POLICY',
    description: 'THE POLICY BELOW SETS OUT THE PRIVACY TERMS THAT APPLY TO RARISSIME \
    These Rarissime Privacy Policy terms are entered into between you (hereinafter referred to as “you” or “your”) and Rarissime, \
    a CLAPART brand, telling you about the rules for using our webpage www.rarissime.clapart.com and www.connectR.clapart.com (hereinafter \
        referred to as “Webpage”). \
    This Privacy Policy is applicable and refers to the data processed through the website www.rarissime.clapart.com and \
    www.connectR.clapart.com and their own subdomains. Document structure: \
    1. The definitions of the terms provided by the GDPR NSAPDP; \
    2. Rarissime overview;\
    3. Contact details;\
    4. Personal data collected, stored and processed by Rarissime;\
    5. Third parties disclosure;\
    6. Your rights and how can you effectively exercise them;\
    7. Children’s personal data – we do not process data for children under 16 years old;\
    8. Security;\
    9. Links to other websites;\
    10. Updates to the privacy policy;\
    11. Information concerning the Romanian Data Protection Supervisory Authority.',
    contents: [
        {
            subtitle: '1.Key terms as defined by the GDPR NSAPDP (The National Supervisory Authority for Personal Data Processing,\
                 the Romanian independent public authority responsible for the compliance with the protection of personal data requirements);',
            subcontent: 'a)Personal data represents any information relating to an identified or identifiable natural person (‘data subject’);\
             an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier \
             such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, \
             physiological, genetic, mental, economic, cultural or social identity of that natural person; \
            b)Processing represents any operation or set of operations which is performed on personal data or on sets of personal data,\
             whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration,\
              retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination,\
               restriction, erasure or destruction; \
            c)Restriction of processing represents the marking of stored personal data with the aim of limiting their processing in the future;\
            d)Controller represents the natural or legal person, public authority, agency or other body which, alone or jointly with others, \
            determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined\
             by the European Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union\
              or Member State law; \
            e)Processor represents a natural or legal person, public authority, agency or other body which processes personal data on behalf\
             of the controller;\
            f)Recipient represents a natural or legal person, public authority, agency or another body, to which the personal data are\
             disclosed, whether it is a third party or not. However, public authorities which may receive personal data in the framework\
              of a particular inquiry in accordance with the European Union or Member State law shall not be regarded as recipients;\
               the processing of those data by those public authorities shall be in compliance with the applicable data protection\
                rules according to the purposes of the processing; \
            g)Third party means a natural or legal person, public authority, agency or body other than the data subject, controller,\
             processor and persons who, under the direct authority of the controller or processor, are authorized to process personal\
              data; Data Breach represents a breach of security leading to the accidental or unlawful destruction, loss, alteration,\
               unauthorized disclosure of, or access to, personal data. This means that a breach is more than just losing personal data.'
        },
        {
            subtitle: '2.Rarissime is an exclusive NFT mint and marketplace developed by CLAPART, which focuses on utility NFT collections featuring the CLAPART artists. Rarissime uses blockchain technology (multi-chain minting) and the CLP token. Our Webpage is a beta technical infrastructure which must be seen only as an online beta tool for presenting and pre-testing (if applicable) our services.. Rarissime observes the confidentiality and security of the personal data constantly ensuring that all personal data are processed only for specific, explicit and legal purposes, \
            according with the principles and provisions of the GDPR. '
        }
    ]
};

export const cookies = {
    header: 'CLAPART RARISSIME COOKIES POLICY',
    description: 'THE AGREEMENT BELOW SETS OUT THE USE OF COOKIES RELATED TO OUR SERVICES \
    The Rarissime Cookies Policy relates to the rules for using our webpage www.rarissime.clapart.com and www.connectR.clapart.com \
    (hereinafter referred to as “Webpage”). Please note that our Webpage is just a presentation of services that are will be provided\
     within the CLAPART ecosystem, such as a NFT collections, a NFT Marketplace, CLP tokens, staking and many more (hereinafter\
         referred to as “Services”). \
    What are cookies? Cookies are small text files that are placed and stored on your device when you visit our website.\
      They make your use of our Services more personal and tailor your experience according to your behaviour and interests.\
    Depending on their purpose, cookies can be classified into several categories, such as:\
    a)Technical cookies which are used to run and protect our website while others are used to make it more efficiently. \
    b)Analytic and advertising cookies which enable us to analyses your activity on our website so that we can serve you\
     better in the future. \
    c)Non-tracking cookies which are used to store your decisions in terms of your ad consent and opt-out choices.\
    Most cookies contain a unique identifier called a cookie ID (a string of characters that websites and servers\
         associate with the browser on which the cookie is stored).\
    It allows our websites and servers to distinguish the browser from other browsers that store different cookies,\
     and to recognize each browser by its unique cookie ID.\
    If we do collect personal data through our website, we will clearly flag this. We’ll make it clear when we collect persona\
    l information, and we’ll explain what we intend to do with it. Please refer to our Privacy Policy.\
    What is Google Analytics? We use a third-party service, Google Analytics, to collect standard internet log information\
     and details of visitor\'s behavior patterns. Google Analytics offers web analytics services that help us to analyze\
      how users interact with our website or with specific parts of it. All the information is processed in a way that\
       does not directly identify anyone. We do not make and do not allow Google to make any attempt to find out the identities\
        of our website\'s visitors. If you want to learn more about Google Analytics, you can find their Privacy Policy here\
        . If we collect personal data on our website, we will inform you in advance. We will clarify when we collect personal\
         information and explain what we intend to do with it.\
    How do I change my cookie settings? You can change your cookie preferences at any time by clicking on the icon. \
    You can adjust the available sliders to ‘On’ or ‘Off’, then clicking ‘Save and close’. You may need to refresh your\
     page for your settings to take effect. Alternatively, most web browsers allow some control of most cookies through\
      the browser settings. To find out more about cookies, including how to see what cookies have been set, visit \
      www.aboutcookies.org or www.allaboutcookies.org.\
    CLAPART RARISSIME\
    Last updated: 1 February 2023 ',
    contents: []
};

export const rarrissimeRaffles = {
    header: 'CLAPART RARISSIME RAFFLE RULES',
    description: 'THE AGREEMENT BELOW SETS OUT THE TERMS & CONDITIONS WHICH GOVERN THE RAFFLES ORGANISED BY CLAPART ON THE RARISSIME NFT STORE \
    These CLAPART Rarissime Raffle Rules are entered into between you (hereinafter referred to as “you” or “your”) and Rarissime, a CLAPART brand,\
     telling you about the rules for using our webpage www.rarissime.clapart.com and www.connectR.clapart.com (hereinafter referred to as “Webpage”).\
      Please note that our Webpage is just a presentation of services that are will be provided within the CLAPART ecosystem, such as a NFT collections,\
       a NFT Marketplace, CLP tokens, staking and many more (hereinafter referred to as “Services”). Thus, please be advised that the terms and conditions\
        of using those Services will be provided therein. By using (in any possible way) our Services, you fully agree that you have read, understood, and\
         accepted all the terms and conditions stipulated in these Raffle Rules (hereinafter referred to as ‘’Terms’’).\
    These Terms constitute a legal agreement and create a binding contract between you and CLAPART Rarissime.',
    contents: [

        {
            subtitle: '1.Rarissime Overview',
            subcontent: 'Rarissime is an exclusive NFT mint and marketplace developed by CLAPART, which focuses on utility NFT collections featuring the CLAPART artists.\
             Rarissime uses blockchain technology (multi-chain minting) and the CLP token. Our Webpage is a beta technical infrastructure which must be seen only as an online\
              beta tool for presenting and pre-testing (if applicable) our Services. We offer no guarantee or assurance of the function ability of the Webpage and/or its\
               Services. Rarissime does not commit and shall not be liable for any failure to support, display or offer any of the features available within the Webpage,\
                especially but not limited to the situation in which a damage is caused by the action of a third party. To contact us, please email at\
                 legal.rarissime@clapart.com.'
        },
        {
            subtitle: '2.Legal Entity',
            subcontent: 'CLAPART Rarissime (or Rarissime) is a brand associated to CLAPART, the trading name of CREATIVE BLOCKCHAIN SOLUTIONS SRL, a Romanian limited liability company registered in Bucharest, with CUI RO45777164,  J40/4594/2022.'
        },
        {
            subtitle: '3.General provisions',
            subcontent: 'WE DO NOT OFFER ANY NFT OR CRYPTOINVESTMENT OR FINANCIAL ADVICE. ANY REFERENCE TO INVESTMENTS DISPLAYED ON THE WEBPAGE IS MENTIONED ONLY FOR INFORMATIVE PURPOSES.\
                Rarissime does not provide NFT or cryptocurrency investment or consulting advice of any kind and is not responsible for the use or interpretation of information available on the Webpage (regardless the uploader) or any other communication method.\
                Any approximation, value or estimation available on the Webpage is pure for informative or explanation purposes and do not constitute a promise or guarantee.\
                 All Rarissime Services are available only to persons who are in full civil capacity according to their national regulation. Rarissime cannot and does\
                  not guarantee the Services (including the content of the Webpage) accuracy, applicability, reliability, integrity, performance, or appropriateness. Rarissime\
                   shall not be liable for any loss or damage that may be caused directly or indirectly by your use of these contents. All users MUST UNDERSTAND AND ASSUME\
                    the risks associated with the use (regardless the method) of Cryptocurrencies/Digital Assets, and it is recommended to exercise a high level of prudence\
                     and responsibly assume decisions within their own capabilities, on their own risk.\
                PLEASE TAKE INTO CONSIDERATION THAT THE VALUE OF THE CLP TOKENS AND ANY OTHER CRYPTOCURRENCY ON THE OPEN MARKET MAY CHANGE BY +/- 100% EVERY SECOND BY REFERENCE TO THE ACQUISITION PRICE OR BY REFERENCE TO ANY PREVIOUS VALUE.'
        },
        {
            subtitle: '4.Raffle Location',
            subcontent: 'The raffle draw takes place online, in digital format, on https://app.randompicker.com/'
        },
        {
            subtitle: '5.Raffle Timing',
            subcontent: 'The raffle draw date will take place after the minting of the collection, but not later than April 23rd. Announcements with the dates will be made on the CLAPART social media.'
        },
        {
            subtitle: '6.Raffle Transparency',
            subcontent: 'For the purpose of ensuring transparency, CLAPART Rarissime will:\
                Use 3rd party randomisation software to draw winners -  https://app.randompicker.com/\
                Stream the process live, on CLAPART social media – Discord Channel \
                Bar any CLAPART Rarissime associates (team, contractors) from winning. Any such win will be forfeited;'
        },
        {
            subtitle: '7.Raffle Participants',
            subcontent: 'All NFT holders can participate in CLAPART Rarissime raffles, according to the rarity of their NFTs. Certain rarities (i.e. Common, Rare, Epic or Legendary) allow participation to certain raffles,\
                as described in the offering of each collection. Such offering and the benefits of each rarity are displayed on the CLAPART Rarissime website.\
                Raffle participants have to be at least 16 years old.\
                The personal data of participants may be stored and processed by CLAPART Rarissime, in accordance with European GDPR regulations and Romanian\
                 relevant laws including Regulation no. 679/2016. We do not collect personal data from children: if you are under 16, please do not provide us\
                  with any personal data.'
        },
        {
            subtitle: '8.Raffle Winners',
            subcontent: 'Winners will be selected using 3rd party electronic randomisation software, on pre-announced dates. \
            Winners will be announced on the Website and CLAPART social media - Discord Channel.\
            Winners take possession of the prizes (i) instantly for electronic rewards such as promo codes, discount codes,\
             online memberships; (ii) no later than in 6 months from the draw in case of physical rewards such as merch, concert tickets\
             , VIP passes, excursions etc. and (iii) no later than in 9 months for royalties derived from artistic/creative works.'
        },
        {
            subtitle: '9.Raffle Winnings Taxation',
            subcontent: 'Raffle winners are solely responsible for declaring any income derived from the raffle to the tax authorities in their country of residence. CLAPART Rarissime takes no liability with regard to the tax filing and reporting on behalf of the raffle winners.'
        },
        {
            subtitle: '10.Raffle PR',
            subcontent: 'The Raffle participants understand that CLAPART Rarissime will advertise the raffle and may use images of the prizes draw and winners for publicity.'
        },
        {
            subtitle: '11.Liability',
            subcontent: 'TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE AND UNDERSTAND THAT RARISSIME WILL NOT BE LIABLE FOR: ANY INDIRECT, SPECIAL,\
             INCIDENTAL, CONSEQUENTIAL, TREBLE OR OTHER MULTIPLES OF DAMAGES, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM OR IN CONNECTION WITH THESE TERMS OR YOUR USE\
              OF THE SERVICES/CLP TOKENS. RARISSIME IS NOT RESPONSIBLE FOR ANY LOST PROFITS, LOST REVENUES, LOST BUSINESS OPPORTUNITIES, DIMINUTION IN VALUE, INCLUDING\
               ANY ALLEGED LOSS OR DIMINUTION IN VALUE OF PERSONAL INFORMATION, OR ANY OTHER LOSSES (COLLECTIVELY, “LOSSES”) ARISING FROM OR IN CONNECTION WITH THESE TERM\
               S OR YOUR USE OF OR ACCESS TO THE SERVICES/CLP TOKENS, INCLUDING, BUT NOT LIMITED TO, LOSSES RESULTING FROM OR IN CONNECTION WITH (BUT NOT LIMITED TO):\
                THE DELETION OF, ALTERATION OF, MIS-DELIVERY OF, OR FAILURE TO STORE DATA MAINTAINED OR TRANSMITTED BY THE SERVICES; THE LIMITING, SUSPENSION OR TERMINATION\
                 OF YOUR ACCOUNT (if applicable); YOUR DOWNLOADING OR SHARING OF INFORMATION, INCLUDING PERSONAL INFORMATION, VIA THE SERVICES; THE UNAUTHORIZED ACCESS\
                  TO YOUR ACCOUNT OR ANY DATA MAINTAINED OR TRANSMITTED BY THE SERVICES (IF APPLICABLE); LINKS PROVIDED BY THE SERVICES OR THIRD PARTIES TO EXTERNAL PAGES\
                   OR RESOURCES; YOUR DEALINGS WITH OR PARTICIPATION IN PROMOTIONS OF ADVERTISERS FOUND ON OR THROUGH THE SERVICES; OR ANY GOOD OR SERVICES SOLD BY SUCH \
                   ADVERTISERS. RARISSIME WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, OF ANY KIND WHICH MAY RISE DIRECT OR INDIRECT FROM OR IN CONNECTION OF ANY DECISION\
                    THAT YOU PERFORM AFTER READING AND/OR INTERACTING IN ANY POSSIBLE WAY WITH THE WEBPAGE’S SERVICES, CLP TOKEN, TECHPART/THIRD PARTY’S SERVICES OR WITH\
                     THE WEBPAGE ITSELF. RARISSIME WILL NOT BE LIABLE FOR PROBLEMS CAUSED BY OTHERS, THE WRONGFUL OR UNLAWFUL ACTIONS OF THIRD PARTIES, OR AN ACT OF GOD.\
                      THE LIMITATIONS AND EXCLUSIONS IN THESE TERMS WILL APPLY WHETHER OR NOT WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY\
                       LOSSES ARISING. TO THE FULLEST EXTENT PERMITTED BY THE LAW AND EXCEPT AS OTHERWISE STATED IN THESE TERMS, RARISSIME IS NOT LIABLE IN CONNECTION WITH\
                        ANY DISPUTES THAT ARISE OUT OF OR RELATE TO THESE TERMS OR SERVICES.'
        },
        {
            subtitle: '12.These Terms May Change',
            subcontent: 'Rarissime reserves the right to change, add or remove parts of these Terms at any time and at its sole discretion. Every time you wish to use our page, please check these terms to ensure you understand the terms that apply at that time. We will notify such changes by simply updating the terms on our webpage and modifying the “Last updated” date displayed at the bottom of this document. All modifications or changes to these terms will become effective upon publication on the webpage or release to users. It is your responsibility to review the amended Terms. Your continued use of the Webpage and the Services following the changes to the Terms, you accept and agree to the changes, and that all subsequent activity performed by you will be subject to the amended Terms. If you have any question regarding or in connection of the information mentioned in these Terms, please do not hesitate to directly contact us. '
        },

        {
            subtitle: '13.Agreement Transfer',
            subcontent: 'The transfer of rights and obligations arising from these Terms and / or the use of the Webpage is subject to our prior written consent. You expressly agree that Rarissime may unilaterally transfer all rights and obligations that may result from these Terms and use of the Webpage without the consent of users. '
        },
        {
            subtitle: '14.Third Party Websites',
            subcontent: 'Where our Webpage contains links to other pages and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked webpages or information you may obtain from them. We have no control over the contents of those pages or resources. For example, on our Webpage you may find links to external webpages such as: links to our Partners’ webpages or to third parties which decided to use/integrate our Services/CLP Tokens. Please note that all external links are displayed for advertising (portfolio) purposes only, and we do not warrant or assume any liability in connection with their actions, promises, content and services.'
        },
        {
            subtitle: '15.Contact & Feedback',
            subcontent: 'If you have a complaint or feedback, please contact us on legal.rarissime@clapart.com.'
        },
        {
            subtitle: '16.Law and Disputes',
            subcontent: 'These terms of use, their subject matter, and their formation (and any non-contractual disputes or claims) are governed by Romanian law terms are governed by Romanian law. We both agree to the exclusive jurisdiction of the courts of Romania. '
        },
        {
            subtitle: 'CLAPART RARISSIME',
            subcontent: 'Last updated: 5 April 2023'
        },
    ]
};


export const clubRarissime = {
    content : [
        'Our vision is to bridge the divide between artists and art lovers, between stars and collectors. \
        To reach this vision, we recognize that at the heart of each project lies a happy and involved community.',
        'This is why we created Club Rarissime, an exclusive community where fans can interact with their favourite artists, \
        gain access to unique opportunities and experiences, and exchange value and ideas with other like-minded individuals.',
        'Access to Club Rarissime will be given only to NFT holders from all Rarissime drops, starting with the Hood Heroes Collection.',
        'Join the most exclusive web 3 community in the region and let us write together the future of the Media and Entertainment industry!'
    ]
};
