import React, { useEffect, useState } from 'react';

import img from 'assets/images/nft-img-big.jpg';
import avatar from 'assets/images/user-avatar-small.jpg';

import { AbiRegistry, Address, AddressValue, ContractFunction, ResultsParser, SmartContract, SmartContractAbi, TransactionPayload } from '@multiversx/sdk-core';

import { useGetAccountInfo, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';

import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';

import mintABI from 'assets/abi/clapart-mint-contract.abi.json';
import { collectionId, conllectionName, contractAddress, contractName, network } from 'config';
import { smartContract } from 'pages/Dashboard/components/Actions/helpers/smartContract';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import axios from 'axios';

import {rarity} from '../../helpers/constant';


import unBoxedLogo from 'assets/images/unboxed-logo.jpg';

/* eslint @typescript-eslint/no-var-requires: "off" */
const MyNFT = () => {

	const { address } = useGetAccountInfo();
	const { network: { apiAddress } } = useGetNetworkConfig();
	const { hasPendingTransactions } = useGetPendingTransactions();
	const [contractInteractor, setContractInteractor] = useState<any>(undefined);

	const isLoggedIn = Boolean(address);
	const networkProvider = new ApiNetworkProvider(apiAddress);
	// const [cliamableNfts , setClaimableNfts] = useState<any>([]);

	const [nfts, setNFTs] = useState<any[]>([]);

	const [getTotalTokensLeft, setGetTotalTokensLeft] = useState<number>(0);

	useEffect(() => {
		const ABI = mintABI as any;
		(async () => {

			const abiRegistry = AbiRegistry.create(ABI);
			const abi = new SmartContractAbi(abiRegistry, [
				contractName
			]);
			const contract = new SmartContract({
				address: new Address(contractAddress),
				abi
			});

			setContractInteractor(contract);

		})();


	}, []);

	useEffect(() => {
		(async () => {
			if (!isLoggedIn || !contractInteractor) return;
			// const query = contractInteractor.createQuery({
			// 	func: new ContractFunction('getAccountNfts'),
			// 	args: [new AddressValue(new Address(address))],
			// 	caller: new Address(address)
			// });
			// const resultsParser = new ResultsParser();
			// const response = await networkProvider.queryContract(query);
			// const endpointDefinition =
			// 	contractInteractor.getEndpoint('getAccountNfts');
			// const res = resultsParser.parseQueryResponse(
			// 	response,
			// 	endpointDefinition
			// );
			// let value = res.firstValue?.valueOf();
			// value = value.map((item: any) => item.toNumber());

			const receiver = await axios.get(`${apiAddress}/accounts/${address}/nfts?size=10000&collection=${collectionId}`);
			// console.log(receiver);
			if (receiver.status == 200 && receiver.data.length > 0) {
				await setNFTs(receiver.data);

			}
			// console.log(nfts);
			// setClaimableNfts(nfts);
		})();

		// (async () => {
		// 	if(!isLoggedIn || !contractInteractor) return;
		// 	const query = contractInteractor.createQuery({
		// 		func: new ContractFunction('getTotalTokensLeft'),
		// 		args: [],
		// 		caller: new Address(address)
		// 	});

		// 	const resultsParser = new ResultsParser();
		// 	const response = await networkProvider.queryContract(query);
		// 	const endpointDefinition = contractInteractor.getEndpoint('getTotalTokensLeft');

		// 	const res = resultsParser.parseQueryResponse(
		// 		response,
		// 		endpointDefinition
		// 	);
		// 	const value = res.firstValue?.valueOf();
		// 	setGetTotalTokensLeft(value.toNumber());
		// 	console.log(value.toNumber());

		// })();

		//example
		// (async () => {
		// 	if(!isLoggedIn || !contractInteractor) return;
		// 	const query = contractInteractor.createQuery({
		// 		func: new ContractFunction('getAddresses'),
		// 		args: [],
		// 		caller: new Address(address)
		// 	});

		// 	const resultsParser = new ResultsParser();
		// 	const response = await networkProvider.queryContract(query);
		// 	const endpointDefinition = contractInteractor.getEndpoint('getAddresses');

		// 	const res = resultsParser.parseQueryResponse(
		// 		response,
		// 		endpointDefinition
		// 	);
		// 	console.log('xxx');
		// 	const value = res.firstValue?.valueOf();
		// 	console.log(value);
		// })();
		//
	}, [contractInteractor, hasPendingTransactions]);

	// const claim = async (e: any) => {

	// 	if(cliamableNfts.length == 0)
	// 		return;

	// 	e.preventDefault();
	// 	const data = new TransactionPayload('claim');
	// 	const gas = 20_000_000;

	// 	const tx = {
	// 		receiver: contractAddress,
	// 		gasLimit: gas,
	// 		value: 0,
	// 		data: data.toString()
	// 	};

	// 	await refreshAccount();
	// 	await sendTransactions({
	// 		transactions: tx,
	//         transactionsDisplayInfo: {
	//           processingMessage: 'Processing Claiming transaction',
	//           errorMessage: 'An error has occured during Claim',
	//           successMessage: 'Claiming transaction successful'
	//         },
	//         redirectAfterSign: false
	// 	});

	// };

	return (
		<main style={{ marginBottom: 32 }}>
			<div className="container nft-page">
				{
					nfts.length > 0? nfts.map((item, index) => (
						<>
							<div className="row" key={index}>

								<div className="col-4 col-12-sm">

									<div className="img-container">
										<img
											src={item.url ? item.url : unBoxedLogo}
											// src = {unBoxedLogo}
											className="nft-image"
											alt=""
										/>
									</div>

									{/* <p className="nft-description">Some short description about this item artwork goes here</p> */}

								</div>

								<div className="col-8 col-12-sm">
									<div className="nft-page-info">

										<h2>{item.name}</h2>

										<div className="mint-collection">
											<small>Collection: <a href="collection.html"><strong>{conllectionName}</strong></a></small>
										</div>

										<div className="mint-tier">
											<small>Tier: <span style = {{
												color:item.metadata? rarity.filter(rar => rar.title.toLowerCase() == item.metadata.attributes[17].value.toLowerCase())[0].color:'black'
											 }}><strong>{item.metadata?item.metadata.attributes[17].value:'???'}</strong></span></small>
										</div>

										<div className="contract mint">
											<span className="contract-label">Contract Address</span>
											<a href={item.url} target="_blank" className="button contract">{contractAddress.slice(0, 4)}...{contractAddress.slice(contractAddress.length - 4, contractAddress.length)}</a>
											<span className="fa fa-copy"></span>
										</div>

										{/* <div className ="separator"></div> */}

										{/* <div className ="row">

								<div className ="col-5 col-7-sm">
									<div className ="user row">
										<div className ="avatar left">
											<img src={avatar} alt="" />
											<div className ="verified"><span className ="fa fa-check green"></span></div>
										</div>
										<div className ="user-info left">
											<p className ="user-role">Created by</p>
											<h3 className ="user-name">Connect-R</h3>
										</div>
									</div>
								</div>

								<div className ="col-4 col-5-sm right">
									<div className ="prices mint right">
										<p className ="price-1" style={{ marginTop: '0.6rem' , fontSize: '15px' }}>
											{cliamableNfts.length} claimable Tokens
										</p>
									</div>
								</div>

								<div className ="col-3 col-12-sm right">
									<div className ="buttons mint">
										<a
											href="#"
											className ="button center button-very-big"
											onClick = {claim}
											style = {{
												pointerEvents: (getTotalTokensLeft > 0)? 'none': 'auto',
												background: (getTotalTokensLeft > 0)? 'gray': 'auto',
												border: (getTotalTokensLeft > 0)? 'gray': 'auto'
											 }}

										>
											Claim
										</a>
									</div>
								</div>

							</div> */}

										{/* <div className="separator"></div> */}

										<h3 className="offers-title">Features</h3>

										<div className="nft-features">
											{
												item.metadata?Object.keys(item.metadata.utilities[0]?item.metadata.utilities[0]:{}).map((_item , _index) => (
													<li key={_index}>{item.metadata.utilities[0][_item]}</li>
												)):''

											}
											{/* <li>Donec in ex a sem condimentum euismod.</li>
											<li>Integer in lorem in nisl imperdiet varius.</li>
											<li>Suspendisse laoreet ligula vitae sapien placerat sodales.</li>
											<li>Proin quis augue non velit sodales rutrum.</li>
											<li>Suspendisse tincidunt lorem vestibulum nulla luctus.</li> */}
										</div>

									</div>
								</div>

							</div>
							<div className="separator" style={{ marginTop: '1.5rem' }}></div>
						</>
					)):''
				}



			</div>
		</main>
	);
};


export { MyNFT };
