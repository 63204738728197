import React from 'react';


interface propTypes{
    info: {
        header: any,
        description: any,
        contents: any
    }
}

const StaticInfo = ({
    info
} : propTypes) => {
    return (
        <>
            <div className = "page-head static">
                <div className = "content">

                    <div className = "row">
                        <h2 className = "static-title">{info.header}</h2>
                    </div>

                    <div className = "row">
                        <p className = "static-title">{info.description}</p>
                    </div>
                </div>
            </div>
            <main>

                {
                    info.contents?.map((item: any) => (
                        <div className = "container static-page">
                            <div className = "row">
                                <div className = "col-12">
                                    <p>{item.subtitle}</p>
                                    <p>{item.subcontent}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }



            </main>
        </>
    );
};


export {StaticInfo};
