export const dAppName = 'HoodHeroes';
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';
export const fixed_precision = 3;
export const decimals = 18;
export const limited_number_of_nfts = 50;

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];


/////////////////////////////////////
/////////                   /////////
/////////   DEVNET CONFIG   /////////
/////////                   /////////
/////////////////////////////////////
// export const contractAddress = 'erd1qqqqqqqqqqqqqpgqpw54eeykas7slqsp822w6te329ldhqpafhvqdumt3s';
// export const contractName = 'HoodHeroes';
// export const collectionId = 'THH-d69a5a';
// export const conllectionName = 'HoodHeroes';
// export const lastStage = 3;
//
// export const network = {
//   id: 'D',
//   name: 'Devnet',
//   egldLabel: 'xEGLD',
//   walletAddress: 'https://devnet-wallet.multiversx.com',
//   apiAddress: 'https://devnet-api.multiversx.com',
//   gatewayAddress: 'https://devnet-gateway.multiversx.com',
//   explorerAddress: 'http://devnet-explorer.multiversx.com',
//   graphQlAddress: 'https://devnet-exchange-graph.multiversx.com/graphql'
// };

// export const network = {
//   id: 'D',
//   name: 'Devnet',
//   egldLabel: 'xEGLD',
//   walletAddress: 'https://devnet-wallet.multiversx.com',
//   apiAddress: 'https://devnet-api.multiversx.com',
//   gatewayAddress: 'https://devnet-gateway.multiversx.com',
//   explorerAddress: 'http://devnet-explorer.multiversx.com',
//   graphQlAddress: 'https://devnet-exchange-graph.multiversx.com/graphql'
// };
//
//
// export const ccproWidgetUrl = 'https://dev-checkout.infra.cryptocoin.pro/order/Clapart/nft/products/CLP_Connect_R?access=9eb449b9-1652-4e5c-8db4-1cc8df01bc03&iframe=true';


//////////////////////////////////////
/////////                    /////////
/////////   MAINNET CONFIG   /////////
/////////                    /////////
//////////////////////////////////////


export const network = {
    id: '1',
    name: 'Mainnet',
    egldLabel: 'xEGLD',
    walletAddress: 'https://wallet.multiversx.com',
    apiAddress: 'https://gateway.multiversx.com',
    explorerAddress: 'http://explorer.multiversx.com',
    graphQlAddress: 'https://exchange-graph.elrond.com/graphql'
  };

export const contractAddress = 'erd1qqqqqqqqqqqqqpgqvw3kgue2qd03y8wv90sv6g40q4tmefj7r5ascnal97';
export const contractName = 'HoodHeroes';
export const collectionId = 'THH-224018';
export const conllectionName = 'HoodHeroes';
export const lastStage = 3;
export const ccproWidgetUrl = 'https://checkout.cryptocoin.pro/order/Clapart/nft/products/CLP_Connect_R?access=e6d63eb3-de8b-4f45-ae7d-75293f4cfa5b';
export const iframeCcproWidgetUrl = 'https://checkout.cryptocoin.pro/order/Clapart/nft/products/CLP_Connect_R?access=e6d63eb3-de8b-4f45-ae7d-75293f4cfa5b&iframe=true&parent=false';
