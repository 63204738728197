import React from 'react';
import Logo from '../../../assets/images/logos/LogoRarissime.png';
import {
  logout
} from '@multiversx/sdk-dapp/utils';
import {
  useGetIsLoggedIn
} from '@multiversx/sdk-dapp/hooks';
// import {
//   faChartSimple,
//   faFileSignature
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
// import { logout } from '@multiversx/sdk-dapp/utils';
// import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { dAppName } from 'config';
// import { routeNames } from 'routes';
// import { ReactComponent as MultiversXLogo } from '../../../assets/img/multiversx.svg';
interface props{
  style: {
    [index: string] : string
  }
}
export const Navbar = (props: props) => {
  const isLoggedIn = useGetIsLoggedIn();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  // return (
  //   <BsNavbar className='bg-white border-bottom px-4 py-3'>
  //     <div className='container-fluid'>
  //       <Link
  //         className='d-flex align-items-center navbar-brand mr-0'
  //         to={isLoggedIn ? routeNames.dashboard : routeNames.home}
  //       >
  //         <MultiversXLogo className='multiversx-logo' />
  //         <span className='dapp-name text-muted'>{dAppName}</span>
  //       </Link>

  //       <Nav className='ml-auto'>
  //         {isLoggedIn && (
  //           <>
  //             <NavItem>
  //               <Link to={routeNames.statistics} className='nav-link'>
  //                 <FontAwesomeIcon
  //                   icon={faChartSimple}
  //                   className='text-muted'
  //                 />
  //               </Link>
  //             </NavItem>
  //             <NavItem>
  //               <Link to={routeNames.signMessage} className='nav-link'>
  //                 <FontAwesomeIcon
  //                   icon={faFileSignature}
  //                   className='text-muted'
  //                 />
  //               </Link>
  //             </NavItem>
  //             <NavItem>
  //               <button className='btn btn-link' onClick={handleLogout}>
  //                 Close
  //               </button>
  //             </NavItem>
  //           </>
  //         )}
  //       </Nav>
  //     </div>
  //   </BsNavbar>
  // );
  console.log(props.style);
  return (
		<header style = { props.style }>
			<div
        className="header"
        style = {{
          borderBottom: '1px solid var(--border)'
         }}
      >

				<div className="row hidden-sm">

					<div className="col-12 col-12-md">

						<a href="/" className="logo left"><img src={Logo} alt="Rarissime by Clapart" /></a>

						<nav className="right">
							<a href="#features">Benefits</a>
							<a href="#roadmap">Roadmap</a>
							<a href="#rarissime_club" >Club Rarissime</a>
							<a href="https://www.clapart.com" >CLAPART</a>
							<a href="#faq" >FAQ</a>
							{/* <a href="info-collection.html">About</a> */}
							<a href="https://www.clapart.com/contact">Contact</a>
              {
                isLoggedIn?
                  <a href="#" onClick={handleLogout}>Disconnect</a>
                  :''
              }
						</nav>
					</div>

				</div>
				<div className="row hidden-lg mobile">

					<div className="col-12 col-12-sm">
						<a href="/" className="logo left"><img src = {Logo} alt="Rarissime by Clapart" /></a>

        		<input className="side-menu" type="checkbox" id="side-menu" />
        		<label className="hamb" htmlFor="side-menu">
							<span className="hamb-line"></span>
						</label>

        		<nav className="mobile-nav">
							<div className="row">
								<div className="col-12 col-12-sm">
            			<ul className="menu">
            				<li><a href="#features">Benefits</a></li>
            				<li><a href="#roadmap">Roadmap</a></li>
										{/* <li><a href="#" style={{ pointerEvents: 'none' , color: 'gray' }}>Explore</a></li> */}
                    <li> <a href="#rarissime_club" >Club Rarissime</a></li>
                    <li><a href="https://www.clapart.com" target="_blank">CLAPART</a></li>
                    <li> <a href="#faq" >FAQ</a></li>
               		  {/* <li><a href="info-collection.html">About</a></li> */}
               		  <li><a href="https://www.clapart.com/contact">Contact</a></li>
                    <li>
                     {
                        isLoggedIn?
                          <a href="#" onClick={handleLogout}>Disconnect</a>
                          :''
                      }
                    </li>
            			</ul>
								</div>
							</div>
        		</nav>
					</div>

				</div>

			</div>
		</header>
  );
};
