import React from 'react';

const About = ({
    title , text
}: {
    title: string , text: string[]
}) => {
    return (
        <div className="container collection-intro">
            <div className="row list-title">
                <div className="landing-title center">
                    <h2>About</h2>
                </div>
                <div className="title-separator"></div>
                <div className="center width-80-text">
                    {
                        text.map((item , index) => (
                            <p key = {index}>
                                {item}
                            </p>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export { About };
