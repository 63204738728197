import React , {useState} from 'react';
import Logo from '../../../assets/images/HoodHeroes.png';


import { Wallets } from './Wallets';
import { Category } from './Category';

const Payment = () => {
	const [status , setStatus] = useState<string>('category');

    return (
		<div className = "full-page">
			<div className = "content small-container">

					<div className = "row">
						<div className = "col-12 col-12-sm center">

							<div className = "logo-big center">
								<a href="/"><img src = {Logo} alt="Rarissime by Clapart" /></a>
							</div>

							<h2 className = "with-note">Payment</h2>
							<p className = "note">Connect with wallet or pay with fiat</p>

							{
								status == 'category'? <Category setStatus = {setStatus} />: <Wallets  />
							}

					  		<p className = "copyright">Copyright &copy; 2023 Rarissime by Clapart. All rights reserved</p>

							<p className = "info-links">
								<a href="info.html">Terms</a>
								<a href="info.html">Privacy</a>
								<a href="info.html">Cookies</a>
							</p>

						</div>
					</div>

			</div>
		</div>
    );
};

export {Payment};
